import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
export default function MassSystemDropdown({
  defaultValue = "",
  setMassSystem
}: {
  defaultValue: string;
  setMassSystem: any;
}) {
  const [select, setSelect] = useState(defaultValue);
  useEffect(() => {
    setMassSystem(select);
  }, [select]);
  return <Select fullWidth value={select} onChange={(e: any) => {
    setSelect(e.target.value);
  }}>
			<MenuItem value={"metric"} key={`Mass Kilogram`}>
				Kilogram
			</MenuItem>
			<MenuItem value={"imperial"} key={`Mass Ounce`}>
				Ounce
			</MenuItem>
		</Select>;
}