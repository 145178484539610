import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
export default function DistanceSystemDropdown({
  defaultValue = "",
  setDistanceSystem
}: {
  defaultValue: string;
  setDistanceSystem: any;
}) {
  const [select, setSelect] = useState(defaultValue);
  useEffect(() => {
    setDistanceSystem(select);
  }, [select]);
  return <Select fullWidth value={select} onChange={(e: any) => {
    setSelect(e.target.value);
  }}>
			<MenuItem value={"metric"} key={`Distance Meter`}>
				Meter
			</MenuItem>
			<MenuItem value={"imperial"} key={`Distance Mile`}>
				Mile
			</MenuItem>
		</Select>;
}