import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
export function EnterTimeManuallyDialog({
  visible,
  closeFunction,
  saveFunction,
  defaultValues
}: {
  visible: boolean;
  closeFunction: any;
  saveFunction: any;
  defaultValues: any;
}) {
  const [endTime, setEndTime] = useState<Dayjs>(defaultValues?.end || dayjs());
  const [startTime, setStartTime] = useState<Dayjs>(defaultValues?.start || dayjs());
  useEffect(() => {
    if (defaultValues?.start) setStartTime(defaultValues?.start);
    if (defaultValues?.end) setEndTime(defaultValues?.end);
  }, [defaultValues]);
  function abortNewDateAndTimes() {
    closeFunction();
  }
  function saveNewDateAndTimes() {
    saveFunction({
      start: startTime,
      end: endTime
    });
    closeFunction();
  }
  return <Dialog open={visible}>
			<DialogTitle>Neue Uhrzeiten eingeben</DialogTitle>
			<DialogContent>
				<TimePicker label={`New Start Time`} value={startTime} onChange={(newValue: any) => {
        setStartTime(newValue);
      }}
      // renderInput={(params) => <TextField size="small" {...params} style={{ width: '50%', margin: '0.5rem 0' }} />}
      />
				<TimePicker label={`New End Time`} value={endTime} onChange={(newValue: any) => {
        setEndTime(newValue);
      }}
      // renderInput={(params) => <TextField size="small" {...params} style={{ width: '50%', margin: '0.5rem 0' }} />}
      />
			</DialogContent>
			<DialogActions>
				<Button onClick={abortNewDateAndTimes}>Abort</Button>
				<Button onClick={saveNewDateAndTimes}>Save</Button>
			</DialogActions>
		</Dialog>;
}