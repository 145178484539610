import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
export function CopyButton({
  value
}: {
  value: string;
}) {
  const {
    t
  } = useTranslation("common");
  function copyFunction() {
    navigator.clipboard.writeText(value);
    toast.success("Erfolgreich kopiert");
  }
  return <TextField label="Link" fullWidth value={value} InputProps={{
    endAdornment: <Button variant="text" onClick={copyFunction}>
						{t("button.copy")}
					</Button>
  }} />;
}