import { addDoc, collection, deleteDoc, doc, setDoc } from "firebase/firestore";
import { db } from "utilities/Firebase/firebase-redux";

export async function addPriceToFirebase(
		data: any,
		uid: string,
		season: string
) {
	const col = collection(db, `/Parks/${uid}/Seasons/${season}/Prices`);
	return await addDoc(col, data);
}

export async function updatePriceDoc(
		priceId: string,
		data: any,
		uid: string,
		season: string
) {
	const ref = doc(db, `/Parks/${uid}/Seasons/${season}/Prices/${priceId}`);
	return await setDoc(ref, data, { merge: true });
}

export async function deletePrice(
		priceId: string,
		uid: string,
		season: string
) {
	const ref = doc(db, `/Parks/${uid}/Seasons/${season}/Prices/${priceId}`);
	return await deleteDoc(ref);
}

export async function addTicketToFirebase(
		data: any,
		uid: string,
		season: string
) {
	const ref = collection(db, `/Parks/${uid}/Seasons/${season}/Tickets`);
	return await addDoc(ref, data);
}

export async function updateTicketDoc(
		ticketId: string,
		data: any,
		uid: string,
		season: string
) {
	const ref = doc(db, `/Parks/${uid}/Seasons/${season}/Tickets/${ticketId}`);
	return await setDoc(ref, data, { merge: true });
}

export async function deleteTicket(
		ticketId: string,
		uid: string,
		season: string
) {
	const ref = doc(db, `/Parks/${uid}/Seasons/${season}/Tickets/${ticketId}`);
	return await deleteDoc(ref);
}

export async function addTicketGroupToFirebase(
		data: any,
		uid: string,
		season: string
) {
	const ref = collection(db, `/Parks/${uid}/Seasons/${season}/Ticket-Groups`);
	return await addDoc(ref, data);
}

export async function updateTicketGroupDoc(
		ticketGroupId: string,
		data: any,
		uid: string,
		season: string
) {
	const ref = doc(
		db,
		`/Parks/${uid}/Seasons/${season}/Ticket-Groups/${ticketGroupId}`
	);
	return await setDoc(ref, data, { merge: true });
}

export async function deleteTicketGroup(
		ticketGroupId: string,
		uid: string,
		season: string
) {
	const ref = doc(
		db,
		`/Parks/${uid}/Seasons/${season}/Ticket-Groups/${ticketGroupId}`
	);
	return await deleteDoc(ref);
}

export async function addTimingToFirebase(
		data: any,
		uid: string,
		season: string
) {
	const col = collection(db, `/Parks/${uid}/Seasons/${season}/Timings`);
	return await addDoc(col, data);
}

export async function updateTimingDoc(
		timingId: string,
		data: any,
		uid: string,
		season: string
) {
	const ref = doc(db, `/Parks/${uid}/Seasons/${season}/Timings/${timingId}`);
	return await setDoc(ref, data, { merge: true });
}

export async function deleteTiming(
		timingId: string,
		uid: string,
		season: string
) {
	const ref = doc(db, `/Parks/${uid}/Seasons/${season}/Timings/${timingId}`);
	return await deleteDoc(ref);
}

export async function addTimingGroupToFirebase(
		data: any,
		uid: string,
		season: string
) {
	const col = collection(db, `/Parks/${uid}/Seasons/${season}/Timing-Groups`);
	return await addDoc(col, data);
}

export async function updateTimingGroupDoc(
		timingId: string,
		data: any,
		uid: string,
		season: string
) {
	const ref = doc(
		db,
		`/Parks/${uid}/Seasons/${season}/Timing-Groups/${timingId}`
	);
	return await setDoc(ref, data, { merge: true });
}

export async function deleteTimingGroup(
		timingId: string,
		uid: string,
		season: string
) {
	const ref = doc(
		db,
		`/Parks/${uid}/Seasons/${season}/Timing-Groups/${timingId}`
	);
	return await deleteDoc(ref);
}
