import { Grid } from "@mui/material";
import { PrivatePageLayout } from "components/common/layouts/Page/private";
import { useTranslation } from "react-i18next";
export function GroupingsPage() {
  const {
    t
  } = useTranslation("common");
  return <PrivatePageLayout link={"https://images.pexels.com/photos/1894350/pexels-photo-1894350.jpeg"} activeLink={"tickets,prices,times"} activeSubLink={"Groupings"} title={t("title.groupings")}>
			<Grid container style={{
      maxWidth: "1000px",
      margin: "auto"
    }} spacing={0}>
				{/* <Grid item xs={12}>
                <TicketPriceGrouping />
            </Grid> */}
				{/* <Grid item xs={12}>
                <TicketTimeGrouping />
            </Grid> */}
			</Grid>
		</PrivatePageLayout>;
}