import type { MaxTimeType } from "components/base/Hours-And-Tickets/Tickets/ticketGroup/edit-ticket-group-dialog";
import type {Dayjs} from "dayjs";
import dayjs from "dayjs";

export type TimeTextFirebaseObject = {
	Text: string;
	Start: {
		DateTime: number;
		Timezone: string;
	};
	End: {
		DateTime: number;
		Timezone: string;
	};
};

export type TicketGroupFirebaseObject = {
	Image: string;
	Title: string;
	Description: string;
	TicketEventGroups: any[];
	TicketGroupId: string;
};

export function findIndexOfExistingDateTimeInArray(date: string, array: any[]) {
	let returnVal;
	array.forEach((item) => {
		if (item?.id !== date) return;
		returnVal = item;
	});
	return returnVal;
}

// calculate dates
// first Object is date giver (Day, Month, Year)
// second Object is time giver (Hour, Minute, Second, Millisecond)
export function calculateDayJsDateOfTwoDates(
		dateDate: Dayjs,
		timeDate: Dayjs
): Dayjs {
	const newDate = dayjs(dateDate?.toDate()?.getTime())
		.set("hour", timeDate.get("hour"))
		.set("minute", timeDate.get("minute"))
		.set("millisecond", timeDate.get("millisecond"));
	return newDate;
}

// calculate dates
// first Object is date giver (Day, Month, Year)
// second Object is time giver (Hour, Minute, Second, Millisecond)
export function subtractDayJsDateOfTwoDates(
		dateDate: Dayjs,
		subtractionDate: Dayjs
): Dayjs {
	const newDate = dayjs(
		dateDate?.toDate()?.getTime() - subtractionDate?.toDate()?.getTime()
	);
	return newDate;
}

export function getDateFromMaxTimeObject(
		maxTimeObject: MaxTimeType,
		startOfTicket: Dayjs
) {
	const isDateSelected = maxTimeObject?.isDateSelected;
	if (!isDateSelected)
		return subtractDayJsDateOfTwoDates(
			dayjs(startOfTicket),
			dayjs(maxTimeObject?.hours)
		);

	const daysOffCalculated: Dayjs = dayjs(startOfTicket).subtract(
		Number(maxTimeObject?.date?.days || 0),
		"day"
	);
	const newDate = calculateDayJsDateOfTwoDates(
		daysOffCalculated,
		dayjs(maxTimeObject?.date?.dateTime || 0)
	);

	return newDate;
}

export function getBusinessHour(
		id: string,
		activeSeasonTimingGroupsRedux: any[]
) {
	let returnVal;
	activeSeasonTimingGroupsRedux?.forEach((businessHour: any) => {
		if (businessHour?.id !== id) return;
		returnVal = businessHour;
	});
	return returnVal;
}

export function getTicketGroupById(
		ticketGroupId: string,
		activeSeasonTicketGroupingsRedux: any[]
) {
	var returnVal;
	activeSeasonTicketGroupingsRedux?.forEach((ticketGrouping: any) => {
		if (ticketGrouping?.id !== ticketGroupId) return;
		returnVal = ticketGrouping;
	});
	return returnVal;
}

export function getPriceById(priceId: string, activeSeasonPricesRedux: any[]) {
	var returnVal;
	activeSeasonPricesRedux?.forEach((price: any) => {
		if (price?.id !== priceId) return;
		returnVal = price;
	});
	return returnVal;
}

export function getTimeText(timeText: any, startOfDayDateTime: number) {
	const startTime = calculateDayJsDateOfTwoDates(
		dayjs(startOfDayDateTime),
		dayjs(timeText?.startTime)
	);
	const endTime = calculateDayJsDateOfTwoDates(
		dayjs(startOfDayDateTime),
		dayjs(timeText?.endTime)
	);
	const newObj = {
		Text: timeText?.text,
		Start: {
			DateTime: startTime.toDate().getTime(),
			Timezone: "Europe/Berlin",
		},
		End: {
			DateTime: endTime.toDate().getTime(),
			Timezone: "Europe/Berlin",
		},
	};
	return newObj;
}

export function getTimeTexts(businessHour: any, startOfDayDateTime: number) {
	// get which time to show
	const newTimeTextArray: TimeTextFirebaseObject[] = []; // Text that is being shown in iFrame between curtain dates (defaults to "closed")
	businessHour?.BusinessHoursTimeTexts?.forEach((timeText: any) => {
		const newObj = getTimeText(timeText, startOfDayDateTime);
		newTimeTextArray.push(newObj);
	});
	return newTimeTextArray;
}

export function getPriceData(
		price: any,
		ticketId: string,
		activeSeasonPricesRedux: any[]
) {
	let returnVal;
	price?.priceIds?.forEach((priceDataIds: any) => {
		if (priceDataIds?.ticketId !== ticketId) return;
		const priceData: any = getPriceById(
			priceDataIds?.priceId,
			activeSeasonPricesRedux
		);
		returnVal = priceData;
	});
	return returnVal;
}

export function getPrices(
		prices: any[],
		ticketId: string,
		activeSeasonPricesRedux: any[]
) {
	const pricesArr: any[] = [];
	let extraPriceReturnVal: any = {};
	prices?.forEach((price: any) => {
		const extraPrice: any = getPriceById(
			price?.extraPriceId,
			activeSeasonPricesRedux
		);

		const priceData: any = getPriceData(
			price,
			ticketId,
			activeSeasonPricesRedux
		);

		const newObj = {
			MinVisitors: price?.minVisitors || 1,
			MaxVisitors: price?.maxVisitors || 2,
			MaxVisitorsClosingTicket: price?.maxVisitorsClosingTicket || 0,
			Price: priceData?.Price || 0,
			Description: priceData?.Description || "",
			ExtraPrice: {
				Title: extraPrice?.Title || "",
				Price: extraPrice?.Price || "",
				Id: extraPrice?.id || "",
			},
		};

		extraPriceReturnVal = {
			Title: extraPrice?.Title || "",
			Price: extraPrice?.Price || "",
			Id: extraPrice?.id || "",
		};
		pricesArr.push(newObj);
	});
	return { prices: pricesArr, extraPrice: extraPriceReturnVal };
}

export function getTickets(
		ticketGroupId: string,
		businessHour: any,
		startOfDayDateTime: number,
		ticketGroupData: any,
		ticket: any,
		activeSeasonPricesRedux: any[]
) {
	const duration = Number(ticket?.Duration);

	const ticketEvents: any[] = [];
	businessHour?.Timings?.forEach((timing: any) => {
		if (!timing?.ticketGroupIds?.includes(ticketGroupId)) return;
		const startTime: number = timing?.startTime;
		const endTime: number = timing?.endTime;
		const durationWithStartTime: number = duration + startTime;
		const startTimeOfTicket: Dayjs = calculateDayJsDateOfTwoDates(
			dayjs(startOfDayDateTime),
			dayjs(startTime)
		);
		const endTimeOfTicket: Dayjs = calculateDayJsDateOfTwoDates(
			dayjs(startOfDayDateTime),
			dayjs(endTime)
		);
		const durationDayjs: Dayjs = calculateDayJsDateOfTwoDates(
			dayjs(startOfDayDateTime),
			dayjs(durationWithStartTime)
		);
		const durationDayjsWithMaxTime: Dayjs =
			durationDayjs?.toDate()?.getTime() >
			endTimeOfTicket?.toDate()?.getTime()
				? endTimeOfTicket
				: durationDayjs;

		const cancellation: Dayjs = getDateFromMaxTimeObject(
			ticketGroupData?.Cancellation,
			startTimeOfTicket
		);

		const confirmation: Dayjs = getDateFromMaxTimeObject(
			ticketGroupData?.Confirmation,
			startTimeOfTicket
		);

		const priceData: any = getPrices(
			ticketGroupData?.TicketPrices,
			ticket?.id,
			activeSeasonPricesRedux
		);

		const ticketEvent = {
			// a single Ticket bspw. 8.30 - 11.30
			start: {
				dateTime: startTimeOfTicket?.toDate()?.getTime(),
				timezone: "Europe/Berlin",
			},
			end: {
				dateTime:
					duration > 0
						? durationDayjsWithMaxTime?.toDate()?.getTime()
						: endTimeOfTicket?.toDate()?.getTime(),
				timezone: "Europe/Berlin",
			},
			cancellation: {
				dateTime: cancellation?.toDate()?.getTime(),
				timezone: "Europe/Berlin",
			},
			confirmation: {
				dateTime: confirmation?.toDate()?.getTime(),
				timezone: "Europe/Berlin",
			},
			duration: {
				dateTime: durationDayjs?.toDate()?.getTime(),
				timezone: "Europe/Berlin",
			},
			ticketId: ticket?.id,
			prices: priceData.prices,
			extraPrice: priceData.extraPrice,
			title: ticket?.Title || "",
			description: ticket?.Description || "",
		};

		ticketEvents?.push(ticketEvent);
	});

	return ticketEvents;
}

export function findTicketFromTicketId(
		ticketId: string,
		activeSeasonTicketsRedux: any[]
) {
	var returnOption: any = {};
	activeSeasonTicketsRedux?.forEach((ticket: any) => {
		if (ticket?.id !== ticketId) return;
		returnOption = ticket;
	});
	return returnOption;
}

export function getTicketEventGroups(
		businessHour: any,
		startOfDayDateTime: number,
		ticketGroupData: any,
		ticketGroupId: string,
		activeSeasonTicketsRedux: any[],
		activeSeasonPricesRedux: any[]
) {
	const ticketEventGroup: any[] = []; // the entire Ticket Event Group bspw. "Schulklasse ab 7. Klasse"
	ticketGroupData?.TicketIds?.forEach((ticketId: any) => {
		const ticket = findTicketFromTicketId(
			ticketId,
			activeSeasonTicketsRedux
		);
		const ticketEvents: any[] = getTickets(
			ticketGroupId,
			businessHour,
			startOfDayDateTime,
			ticketGroupData,
			ticket,
			activeSeasonPricesRedux
		);
		const newData = {
			Title: ticket?.Title || "",
			Description: ticket?.Description || "",
			TicketId: ticketId || "",
			TicketEvents: ticketEvents,
		};
		ticketEventGroup.push(newData);
	});
	return ticketEventGroup;
}

export function getTicketGroups(
		businessHour: any,
		startOfDayDateTime: number,
		activeSeasonTicketGroupingsRedux: any[],
		activeSeasonTicketsRedux: any[],
		activeSeasonPricesRedux: any[]
) {
	// get unique ticketGroupIds
	const uniqueTicketGroupIds: string[] = [];
	businessHour?.Timings?.forEach((timingData: any) => {
		timingData?.ticketGroupIds?.forEach((ticketGroupId: string) => {
			if (uniqueTicketGroupIds?.includes(ticketGroupId)) return;
			uniqueTicketGroupIds.push(ticketGroupId);
		});
	});

	const ticketGroups: TicketGroupFirebaseObject[] = []; // Ticket Grouping bspw. //"Schulklasse"
	uniqueTicketGroupIds?.forEach((ticketGroupId) => {
		const ticketGroupData: any = getTicketGroupById(
			ticketGroupId,
			activeSeasonTicketGroupingsRedux
		);
		const ticketEventGroups: any[] = getTicketEventGroups(
			businessHour,
			startOfDayDateTime,
			ticketGroupData,
			ticketGroupId,
			activeSeasonTicketsRedux,
			activeSeasonPricesRedux
		);
		const newData: TicketGroupFirebaseObject = {
			Title: ticketGroupData?.Title || "",
			Image: ticketGroupData?.Image || "",
			Description: ticketGroupData?.Description || "",
			TicketGroupId: ticketGroupId,
			TicketEventGroups: ticketEventGroups,
		};
		ticketGroups.push(newData);
	});

	return ticketGroups;
}
