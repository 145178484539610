import { Button, Grid, TextField } from "@mui/material";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { db } from "utilities/Firebase/firebase-redux";
export default function BasicInformation() {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const park = useSelector((state: any) => state.firestore.ordered.park);
  const [parkTitle, setParkTitle] = useState("");
  const [parkWebsite, setParkWebsite] = useState("");
  const [customlinkName, setCustomLinkName] = useState("");
  const [termsAndConditionsLink, setTermsAndConditionsLink] = useState("");
  const [maxPeopleAtOne, setMaxPeopleAtOnce] = useState("");
  const [category, setCategory] = useState("");
  const [parkWebsiteError, setParkWebsiteError] = useState(false);
  const [termsAndConditionsError, setTermsAndConditionsError] = useState(false);
  useEffect(() => {
    if (!park) return;
    setParkTitle(park[0]?.ParkTitle || "");
    setParkWebsite(park[0]?.Website || "");
    setCustomLinkName(park[0]?.LinkName || "");
    setTermsAndConditionsLink(park[0]?.TermsAndConditionsLink || "");
    setMaxPeopleAtOnce(park[0]?.MaxPeopleAtOne || "");
    setCategory(park[0]?.Category || "");
  }, [park]);
  function isURLValid(value: string) {
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value);
  }
  function submitHandler(event: any) {
    event.preventDefault();
    if (!uid) {
      toast.error("Not logged in");
      return;
    }
    const myDoc = doc(db, `/Parks/${uid}`);
    const newData = {
      ParkTitle: parkTitle,
      Website: parkWebsite,
      LinkName: customlinkName,
      TermsAndConditionsLink: termsAndConditionsLink,
      MaxPeopleAtOne: maxPeopleAtOne,
      Category: category
    };
    updateDoc(myDoc, newData).then(() => {
      toast.success("Updated Basic Information");
    }).catch(err => {
      toast.error("There was an error while Updating your Basic Information");
    });
  }
  return <AccordionCustomComponent title={"Basic Information"}>
			<form onSubmit={submitHandler}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextField required fullWidth label="Park Title" size="small" value={parkTitle} onChange={e => {
            setParkTitle(e?.target?.value);
          }} />
					</Grid>
					<Grid item xs={12}>
						<TextField error={parkWebsiteError} onBlur={() => {
            setParkWebsiteError(!isURLValid(parkWebsite));
          }} required fullWidth label="Park Website" size="small" value={parkWebsite} onChange={e => {
            setParkWebsite(e?.target?.value);
          }} />
					</Grid>
					<Grid item xs={12}>
						<TextField error={termsAndConditionsError} onBlur={() => {
            if (termsAndConditionsLink === "") return;
            setTermsAndConditionsError(!isURLValid(termsAndConditionsLink));
          }} required fullWidth label="Terms and Conditions Link" size="small" value={termsAndConditionsLink} onChange={e => {
            setTermsAndConditionsLink(e?.target?.value);
          }} />
					</Grid>
					{/* <Grid item xs={6}>
                    <Typography>Park Category</Typography>
                    <ParkCategorySelect key={`Park Category Select ${category}`} setCategory={setCategory} defaultValue={category} required />
                </Grid> */}

					{/* <Grid item xs={12}>
                    <TextField fullWidth label="Link Name" size="small" placeholder="https://website.de/your-link" value={customlinkName} onChange={(e)=>{setCustomLinkName(e?.target?.value)}}/>
                </Grid> */}
					{/* <Grid item xs={12}>
                    <TextField type={"number"} fullWidth label="Max Besucher auf einmal" size="small" value={maxPeopleAtOne} onChange={(e)=>{setMaxPeopleAtOnce(e?.target?.value)}}/>
                </Grid> */}
					<Grid item xs={12}>
						<Button style={{
            borderRadius: "1rem"
          }} variant="contained" fullWidth type="submit">
							Update
						</Button>
					</Grid>
				</Grid>
			</form>
		</AccordionCustomComponent>;
}