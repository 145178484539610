import { Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import type { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import { ExportFile } from "./export-file";
import { toast } from "react-toastify";
export function ExportEventsOnDateButton({
  events
}: {
  events: any[];
}) {
  const [date, setDate] = useState<Dayjs>();
  const pdfRef = useRef(null);
  function createPDFandDownload() {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4"
    });
    const content = pdfRef.current;
    if (!content) {
      toast.error("Es gab einen Fehler mit dem Export");
      return;
    }
    doc.html(content, {
      async callback(doc) {
        await doc.save(`export-${date?.toDate()?.toLocaleDateString("de")}.pdf`);
      }
    });
  }
  useEffect(() => {
    events?.forEach(event => {
      console.log({
        event
      });
    });
  }, [events, date]);
  return <AccordionCustomComponent title={"Export"}>
			<div style={{
      padding: "0 0.5rem",
      textAlign: "start"
    }} id="Events Calendar Export">
				<DatePicker sx={{
        width: "100%"
      }} label="Export Datum" value={date} onChange={(newValue: Dayjs | undefined | null) => {
        if (!newValue) return;
        setDate(newValue);
      }} />
				<div style={{
        padding: "0.5rem"
      }}></div>
				<Button disabled={!date} fullWidth onClick={createPDFandDownload}>
					Export
				</Button>
			</div>
			<div ref={pdfRef}>
				<ExportFile localeDateString={date?.toDate()?.toLocaleDateString("de") || "Datum"} events={events} />
			</div>
		</AccordionCustomComponent>;
}