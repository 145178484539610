import { Grid } from "@mui/material";
import Workflows from "components/base/Settings/Workflows";
import { PrivatePageLayout } from "components/common/layouts/Page/private";
import { useTranslation } from "react-i18next";
export function WorkflowSettingsPage() {
  const {
    t
  } = useTranslation("common");
  return <PrivatePageLayout link={"https://images.pexels.com/photos/16737194/pexels-photo-16737194.jpeg?auto=compress&cs=tinysrgb"} activeLink={"Settings"} activeSubLink={"Workflows"} title={t("title.workflows")}>
            <Grid container style={{
      maxWidth: "500px",
      margin: "auto"
    }} spacing={0}>
                <Grid item xs={12}>
                    <Workflows />
                </Grid>
            </Grid>
        </PrivatePageLayout>;
}