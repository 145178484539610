import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
export default function TimeFormatSystemDropdown({
  defaultValue = "",
  setTimeFormat
}: {
  defaultValue: string;
  setTimeFormat: any;
}) {
  const [select, setSelect] = useState(defaultValue);
  useEffect(() => {
    setTimeFormat(select);
  }, [select]);
  return <Select fullWidth value={select} onChange={(e: any) => {
    setSelect(e.target.value);
  }}>
			<MenuItem value={"24h"} key={`Distance Meter`}>
				24h
			</MenuItem>
			<MenuItem value={"am/pm"} key={`Distance Mile`}>
				AM/PM
			</MenuItem>
		</Select>;
}