import { Button } from "@mui/material";
import { KeyboardBackspaceTwoTone } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
export function BackButton({
  onClick
}: {
  onClick?: any;
}) {
  const {
    t
  } = useTranslation("common");
  return <Button variant="text" onClick={onClick}>
			<KeyboardBackspaceTwoTone />
			{t("button.back")}
		</Button>;
}