import { Button } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import { db, functions } from "utilities/Firebase/firebase-redux";
import AccordionCustomComponent from "components/common/layouts/Accordion";
export default function StripeConnect() {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const stripeConnect = useSelector((state: any) => state.firestore.ordered.stripeConnect);
  const payoutInfo = useSelector((state: any) => state.firestore.ordered.payout_info);
  const [loading, setLoading] = useState(false);
  const [isStripeRegistered, setStripeIsRegistered] = useState(false);
  const [isStripeReadyToBeRegistered, setStripeIsReadyToBeRegistered] = useState(false);
  useEffect(() => {
    if (!stripeConnect) return;
    if (stripeConnect[0]?.IsRegistrationFinished) setStripeIsRegistered(true);
  }, [stripeConnect]);
  useEffect(() => {
    if (!payoutInfo) return;
    if (payoutInfo[0]?.Country && payoutInfo[0]?.AccountHolderName && payoutInfo[0]?.AccountNumber && payoutInfo[0]?.Currency && payoutInfo[0]?.BIC) setStripeIsReadyToBeRegistered(true);
  }, [payoutInfo]);
  function createStripeAccount() {
    setLoading(true);
    const browser = navigator.userAgent;
    const ip = "1.1.1.1";
    const date = Math.round(Number(new Date().getTime() / 1000));

    // trigger cloud function
    const createStripeConnectCloudFunction = httpsCallable(functions, "onCreateStripeConnectCall");
    createStripeConnectCloudFunction({
      browser,
      ip,
      date,
      isTOSChecked: true
    }).then(res => {
      toast.success("Created Stripe Account!");
      toast.warn("Waiting for Stripe Link");

      // wait for document to update and have stripe link in it
      const myDoc = doc(db, `/Parks/${uid}/stripe_connect/account`);
      onSnapshot(myDoc, res => {
        const data = res.data();
        if (!data?.linkUrl) return;
        window.location.href = data?.linkUrl;
      });
    }).catch(err => {
      console.log({
        err
      });
      setLoading(false);
      toast.error("There was an Error while creating your Account");
    });
  }
  return <AccordionCustomComponent title={"Stripe"}>
			{!isStripeRegistered && <Button onClick={createStripeAccount} disabled={loading || !isStripeReadyToBeRegistered} variant="contained" fullWidth>
					Create Stripe Account
					<MoonLoader loading={loading}
      // cssOverride={override}
      size={25} aria-label="Loading Spinner" data-testid="loader" />
				</Button>}
			{isStripeRegistered && <Button onClick={createStripeAccount} disabled={loading || !isStripeReadyToBeRegistered} variant="contained" fullWidth>
					Go to Stripe Account
					<MoonLoader loading={loading}
      // cssOverride={override}
      size={25} aria-label="Loading Spinner" data-testid="loader" />
				</Button>}
		</AccordionCustomComponent>;
}