import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listWeekPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import { useEffect, useRef, useState } from "react";
import { Paper } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { db } from "utilities/Firebase/firebase-redux";
import { deleteDoc, doc } from "firebase/firestore";
export function EventCalendarDashboard({}: {}) {
  const bookingsRedux = useSelector((state: any) => state.firestore.ordered.bookings);
  const reservationsRedux = useSelector((state: any) => state.firestore.ordered.reservations);
  const ticketsRedux = useSelector((state: any) => state.firestore.ordered.tickets);
  const [events, setEvents] = useState<any[]>([]);
  useEffect(() => {
    console.log({
      reservationsRedux,
      bookingsRedux
    });
    const reservationEvents: any[] = [];
    reservationsRedux?.forEach((reservation: any) => {
      reservation?.Tickets?.forEach((ticket: any) => {
        const startDate = new Date(ticket?.StartDate?.seconds * 1000);
        const endDate = new Date(ticket?.EndDate?.seconds * 1000);
        const event = {
          title: `${reservation?.PersonalInformation?.FirstName} ${reservation?.PersonalInformation?.LastName}`,
          start: startDate,
          end: endDate,
          // color: myTicket?.color, // random colors for each event, with outline of ticket color
          editable: false,
          startEditable: false,
          durationEditable: false,
          extendedProps: {
            id: reservation?.id,
            isEventBooking: reservation?.PaymentMethod === "cash" ? false : true,
            vititors: ticket?.Visitors,
            first_name: reservation?.PersonalInformation?.FirstName,
            last_name: reservation?.PersonalInformation?.LastName,
            email: reservation?.PersonalInformation?.Email,
            tel: reservation?.PersonalInformation?.PhoneNumber,
            postal_code: reservation?.PersonalInformation?.PostCode,
            city: reservation?.PersonalInformation?.City,
            address_line_1: reservation?.PersonalInformation?.AddressLine1,
            address_line_2: reservation?.PersonalInformation?.AddressLine2,
            // tickets: event?.Tickets,
            message: reservation?.Message,
            ticket: ticket
          },
          description: ""
        };
        reservationEvents.push(event);
      });
    });
    setEvents(reservationEvents);
  }, [reservationsRedux]);
  const calendarEl: any = useRef(null);
  const [selectedEvent, setSelectedEvent] = useState<any>({});
  const [eventChangeKey, setEventChangeKey] = useState(0);
  const [addResKey, setAddResKey] = useState(0);
  const [whyDidReservierungChangeKey, setWhyDidReservierungChangeKey] = useState(0);
  const [isAddReservierungVisible, setIsAddReservierungVisible] = useState(false);
  const [isEditReservierungVisible, setIsEditReservierungVisible] = useState(false);
  const [isWhyDidEventChangeVisible, setIsWhyDidEventChangeVisible] = useState(false);
  const [isShowEachTicket, setIsShowEachTicket] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<any[]>([]);
  // const [events, setEvents] = useState<any[]>([]);
  const [eventTickets, setEventTickets] = useState<any[]>([]);
  // const [eventTicketsUnworked, setEventTicketsUnworked] = useState<any[]>([])
  const [calendarVisibleTickets, setCalendarVisibleTickets] = useState<any[]>([]);
  const [isShowReservationsActive, setShowReservationsIsActive] = useState<boolean>(true);
  const [isShowBookingsActive, setShowBookingsIsActive] = useState<boolean>(true);
  // const [selectedId, setSelectedId] = useState<string>(
  //     reservationId || bookingId || ""
  // );
  const [isEventBooking, setEventIsBooking] = useState<boolean>(false);
  const [isAreYouSureVisible, setAreYouSureIsVisible] = useState(false);

  // useEffect(() => {
  //     if (!reservationId && !bookingId) return;
  //     setSelectedId(reservationId || bookingId || "");
  //     setIsEditReservierungVisible(true);
  //     setEventIsBooking(bookingId ? true : false);
  // }, [reservationId, bookingId, isBooking]);

  // useEffect(() => {
  //     const eventsArray: any[] = [];
  //     if (!bookingsRedux) return;
  //     if (isShowBookingsActive) {
  //         bookingsRedux?.forEach((booking: any) => {
  //             eventsArray.push(booking);
  //         });
  //     }

  //     if (!reservationsRedux) return;
  //     if (isShowReservationsActive) {
  //         reservationsRedux?.forEach((reservation: any) => {
  //             eventsArray.push(reservation);
  //         });
  //     }
  //     setSelectedEvents(eventsArray);
  //     // console.log({bookingsRedux, reservationsRedux, eventsArray})
  // }, [
  //     bookingsRedux,
  //     reservationsRedux,
  //     isShowReservationsActive,
  //     isShowBookingsActive,
  // ]);

  // useEffect(() => {
  //     if (!events || !eventTickets) return;
  //     if (isShowEachTicket) {
  //         setCalendarVisibleTickets(eventTickets);
  //     } else {
  //         setCalendarVisibleTickets(events);
  //     }
  // }, [isShowEachTicket, events, eventTickets]);

  function getEarliestTicketStartDate(tickets: any[]) {
    var returnDate: Date = new Date(0);
    tickets?.forEach(ticket => {
      const ticketTime: Date = new Date(ticket?.StartDate?.seconds * 1000);
      if (returnDate.getTime() >= ticketTime.getTime()) return;
      returnDate = ticketTime;
    });
    return returnDate;
  }
  function getLatestTicketEndDate(tickets: any[]) {
    var returnDate: Date = new Date(0);
    tickets?.forEach(ticket => {
      const ticketTime: Date = new Date(ticket?.EndDate?.seconds * 1000);
      if (returnDate.getTime() >= ticketTime.getTime()) return;
      returnDate = ticketTime;
    });
    return returnDate;
  }

  // useEffect(() => {
  //     const eventDataNew: any[] = [];
  //     const eventDataByTicketNew: any[] = [];
  //     if (!selectedEvents || !ticketsRedux) return;
  //     selectedEvents.forEach((event: any) => {
  //         // event Data
  //         const myTicket = getTicket(event?.Tickets[0]?.TicketId);
  //         const startTime = getEarliestTicketStartDate(event?.Tickets);
  //         const endTime = getLatestTicketEndDate(event?.Tickets);

  //         eventDataNew.push({
  //             title: `${event?.PersonalInformation?.FirstName} ${event?.PersonalInformation?.LastName}`,
  //             start: startTime,
  //             end: endTime,
  //             color: myTicket?.color, // random colors for each event, with outline of ticket color
  //             editable: true,
  //             startEditable: true,
  //             durationEditable: true,
  //             extendedProps: {
  //                 id: event?.id,
  //                 isEventBooking:
  //                     event?.PaymentMethod === "cash" ? false : true,
  //                 vititors: event?.VisitorAmount,
  //                 first_name: event?.PersonalInformation?.FirstName,
  //                 last_name: event?.PersonalInformation?.LastName,
  //                 email: event?.PersonalInformation?.Email,
  //                 tel: event?.PersonalInformation?.PhoneNumber,
  //                 postal_code: event?.PersonalInformation?.PostCode,
  //                 city: event?.PersonalInformation?.City,
  //                 address_line_1: event?.PersonalInformation?.AddressLine1,
  //                 address_line_2: event?.PersonalInformation?.AddressLine2,
  //                 // tickets: event?.Tickets,
  //                 message: event?.Message,
  //                 ticket: myTicket,
  //             },
  //             description: "",
  //         });

  //         // event Data Ticket
  //         event?.Tickets?.forEach((eventTicket: any) => {
  //             const myTicket = getTicket(eventTicket?.TicketId);
  //             const startTimeTicket = new Date(
  //                 eventTicket?.StartDate?.seconds * 1000
  //             );
  //             const endTimeTicket = new Date(
  //                 eventTicket?.EndDate?.seconds * 1000
  //             );
  //             eventDataByTicketNew.push({
  //                 title: `${event?.PersonalInformation?.FirstName} ${event?.PersonalInformation?.LastName} ${myTicket?.Title}`,
  //                 start: startTimeTicket,
  //                 end: endTimeTicket,
  //                 color: myTicket?.color, // random colors for each event but not event Ticket, with outline of ticket color
  //                 editable: true,
  //                 startEditable: true,
  //                 durationEditable: true,
  //                 extendedProps: {
  //                     id: `${event?.id}`,
  //                     isEventBooking:
  //                         event?.PaymentMethod === "cash" ? false : true,
  //                     vititors: eventTicket?.Visitors,
  //                     ticket: myTicket,
  //                 },
  //                 description: "",
  //             });
  //         });
  //     });
  //     // console.log({eventDataNew, eventDataByTicketNew})
  //     // setEvents(eventDataNew);
  //     setEventTickets(eventDataByTicketNew);
  // }, [selectedEvents, ticketsRedux]);

  // function getTicket(id: string) {
  //     if (!ticketsRedux) return;
  //     var returnObj: any = {};
  //     ticketsRedux.forEach((ticket: any) => {
  //         if (ticket.id === id) {
  //             returnObj = ticket;
  //         }
  //     });
  //     return returnObj;
  // }

  function eventClick(event: any) {
    console.log({
      event
    }, event?.event);
  }
  return <Paper style={{
    padding: "0.5rem",
    margin: "auto"
  }}>
            <FullCalendar locale={deLocale} locales={[deLocale]} key={eventChangeKey} ref={calendarEl} firstDay={1} nowIndicator now={new Date()} dayMaxEvents={3} headerToolbar={{
      start: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
      center: "title",
      end: "today prev,next"
    }} events={events} plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listWeekPlugin]} initialView="timeGridWeek" eventClick={eventClick}
    // eventContent={eventContent}
    height={"80vh"} />
        </Paper>;
}