import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import { db } from "utilities/Firebase/firebase-redux";
import { useNavigate } from "react-router";
export default function RegistrationFormEmail() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [isInfoDialogVisible, setInfoDialogIsVisible] = useState(false);
  async function createParkRequestDocument(data: any) {
    const myDoc = collection(db, `/Requests/Parks/New-Parks/`);
    return await addDoc(myDoc, data);
  }
  function registerPark(event: any) {
    event.preventDefault();
    const data = {
      time: Date.now(),
      email: email,
      phoneNumber: phoneNumber,
      firstName: firstName,
      lastName: lastName,
      businessName: businessName
    };
    createParkRequestDocument(data).then(res => {
      setInfoDialogIsVisible(true);
      toast.success("Your Registration Request was sent");
    }).catch(err => {
      toast.error("Something went wrong while processing your Registration Request");
    });
  }
  return <form onSubmit={registerPark}>
			<Dialog open={isInfoDialogVisible}>
				<DialogTitle>Registration</DialogTitle>
				<DialogContent>
					We have received your request to register an account with
					us. Please wait until we reach out to you to continue your
					registration.
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {
          navigate("/login");
        }}>
						Understood
					</Button>
				</DialogActions>
			</Dialog>
			<Grid container spacing={1} style={{
      maxWidth: "500px",
      margin: "auto"
    }}>
				<Grid item xs={12}>
					<TextField variant="standard" required type="email" label="Email-Address" fullWidth value={email} onChange={(e: any) => {
          setEmail(e.target?.value);
        }} />
				</Grid>
				<Grid item xs={12}>
					<TextField variant="standard" required type="tel" label="Phone Number" fullWidth value={phoneNumber} onChange={(e: any) => {
          setPhoneNumber(e.target?.value);
        }} />
				</Grid>
				<Grid item xs={6}>
					<TextField variant="standard" required type="text" label="First Name" fullWidth value={firstName} onChange={(e: any) => {
          setFirstName(e.target?.value);
        }} />
				</Grid>
				<Grid item xs={6}>
					<TextField variant="standard" required type="text" label="Last Name" fullWidth value={lastName} onChange={(e: any) => {
          setLastName(e.target?.value);
        }} />
				</Grid>
				<Grid item xs={12}>
					<TextField variant="standard" required type="text" label="Name of your Business" fullWidth value={businessName} onChange={(e: any) => {
          setBusinessName(e.target?.value);
        }} />
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth type="submit" variant="contained">
						Register
					</Button>
				</Grid>
			</Grid>
		</form>;
}