import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormDialogLayout } from "components/common/layouts/Dialog/form-dialog-layout";
import { useFormik } from "formik";
import { updateTicketGroupDoc } from "../../lib/firebase-helper";
import { useTranslation } from "react-i18next";
import { PriceGroupingItem } from "./grouping-item";
type TicketPriceType = {
  minVisitors: number;
  maxVisitors: number;
  extraPriceId: string;
  priceIds: any[];
  minVisitorsClosingTicket: number;
  emailTemplateId?: string;
  webhook?: string;
};
type TicketPriceGroupType = {
  ticketPrices: TicketPriceType[];
};
export default function EditTicketPriceGroupDialog({
  visible,
  closeFunction,
  id,
  deleteTicketFunction
}: {
  visible: boolean;
  closeFunction: any;
  id: string | null;
  deleteTicketFunction: any;
}) {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);
  const activeSeasonTicketGroupingsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTicketsGroups);
  const activeSeasonPricesRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonPrices);
  const activeSeasonTicketsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTickets);
  const {
    t
  } = useTranslation("common");
  const [pricesSorted, setPricesSorted] = useState<any[]>([]);
  const [activeTicketGrouping, setActiveTicketGrouping] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik<TicketPriceGroupType>({
    initialValues: {
      ticketPrices: []
    },
    onSubmit: updateTicket
  });
  useEffect(() => {
    if (!activeSeasonPricesRedux) return;
    const newArr = structuredClone(activeSeasonPricesRedux);
    newArr?.sort((a: any, b: any) => {
      return String(a?.Title).localeCompare(b?.Title);
    });
    setPricesSorted(newArr);
  }, [activeSeasonPricesRedux]);
  useEffect(() => {
    if (!id) return;
    activeSeasonTicketGroupingsRedux?.forEach((ticketData: any) => {
      if (ticketData.id !== id) return;
      formik.setFieldValue("ticketPrices", ticketData?.TicketPrices || []);
      setActiveTicketGrouping(ticketData);
    });
  }, [activeSeasonTicketGroupingsRedux, id]);
  function updateTicket(values: TicketPriceGroupType) {
    if (!id) return;
    setIsLoading(true);
    const newData = {
      TicketPrices: values.ticketPrices,
      UpdatedOn: Date.now()
    };
    updateTicketGroupDoc(id, newData, uid, activeSeasonRedux[0]?.id).then(() => {
      setIsLoading(false);
      toast.success("Updated Ticket Price Group");
      closeFunction();
    }).catch(error => {
      setIsLoading(false);
      toast.error("Error while updating Ticket Price Group");
      console.log({
        error
      });
    });
  }
  function addGrouping() {
    const newArr = structuredClone(formik.values.ticketPrices);
    newArr.push({
      minVisitors: 1,
      maxVisitors: 2,
      extraPriceId: "",
      minVisitorsClosingTicket: -1,
      priceIds: activeTicketGrouping?.TicketIds?.map(() => ""),
      webhook: "",
      emailTemplateId: ""
    });
    formik.setFieldValue("ticketPrices", newArr);
  }
  function removeGrouping(index: number) {
    const newArr = structuredClone(formik.values.ticketPrices);
    newArr.splice(index, 1);
    formik.setFieldValue("ticketPrices", newArr);
  }
  function handleFormikChangeInMap(ticketPriceIndex: number, field: string, value: number | string) {
    const newArr = structuredClone(formik.values.ticketPrices);
    const nowObj: any = newArr[ticketPriceIndex];
    nowObj[field] = value;
    formik.setFieldValue("ticketPrices", newArr);
  }
  function handleFormikPriceIdChangeInMap(ticketPriceIndex: number, value: any, priceIdIndex: number) {
    const newArr = structuredClone(formik.values.ticketPrices);
    const nowObj: any = newArr[ticketPriceIndex];
    if (nowObj.priceIds?.length < priceIdIndex) nowObj.priceIds.push(value);else nowObj.priceIds[priceIdIndex] = value;
    formik.setFieldValue("ticketPrices", newArr);
  }
  return <FormDialogLayout isOpen={visible} title={"Edit Ticket Price Grouping"} closeFunction={() => {
    closeFunction();
  }} leftButton={<Button fullWidth variant="contained" color="error" onClick={deleteTicketFunction}>
				Delete
			</Button>} submitFunction={formik.handleSubmit} loading={isLoading}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Button onClick={addGrouping} fullWidth>
						Add a new grouping
					</Button>
				</Grid>
				<Grid item xs={12}>
					{formik?.values?.ticketPrices?.map((priceGroup: any, index: number) => {
          return <PriceGroupingItem index={index} removeGrouping={removeGrouping} handleFormikChangeInMap={handleFormikChangeInMap} handleFormikPriceIdChangeInMap={handleFormikPriceIdChangeInMap} priceGroup={priceGroup} pricesSorted={pricesSorted} activeTicketGrouping={activeTicketGrouping} />;
        })}
				</Grid>
			</Grid>
		</FormDialogLayout>;
}