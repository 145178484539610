import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import COUNTRYCODES from "../../../../../data/country-codes.json";
export function PhonePrefixSelect({
  setPhonePrefix,
  defaultValue = "",
  required
}: {
  setPhonePrefix: any;
  defaultValue?: string;
  required?: boolean;
}) {
  const [select, setSelect] = useState<string>(defaultValue);
  useEffect(() => {
    setPhonePrefix(select);
  }, [select]);
  return <Select size="small" required={required} fullWidth value={select} defaultValue={defaultValue} onChange={(e: any) => {
    setSelect(e.target.value);
  }}>
			{COUNTRYCODES?.map(country => {
      return <MenuItem value={country.dial_code} key={`Phone Prefix ${country.dial_code} ${country.name}`}>
						{country.name} {country.dial_code}
					</MenuItem>;
    })}
		</Select>;
}