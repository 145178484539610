import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "styles/index.css";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "./utilities/i18n/i18n";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { rrfProps, store } from "./utilities/Firebase/firebase-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ThemeProvider } from "@mui/material";
import { defaultTheme } from "./themes/default";
import dayjs from "dayjs";
import "dayjs/locale/de";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("de");
dayjs.tz.setDefault("Europe/Berlin");
const container = document.getElementById("root")!;
const root = createRoot(container);
Sentry.init({
  dsn: "https://875ddba33a1a475e85d3de2a13f15577@o4504787641892864.ingest.sentry.io/4504787655458816",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});
root.render(<Provider store={store}>
		<ReactReduxFirebaseProvider {...rrfProps}>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
				<ThemeProvider theme={defaultTheme}>
					<ToastContainer />
					<Suspense>
						<App />
					</Suspense>
				</ThemeProvider>
			</LocalizationProvider>
		</ReactReduxFirebaseProvider>
	</Provider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();