import { Grid, IconButton, Paper, TextField, Tooltip } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
export function BusinessHoursTimeText({
  startTime,
  endTime,
  text,
  setBusinessHourTimeText,
  deleteBusinessHourTimeText
}: {
  startTime: number;
  endTime: number;
  text: string;
  setBusinessHourTimeText: any;
  deleteBusinessHourTimeText: any;
}) {
  const [startTimeLocal, setStartTimeLocal] = useState<Dayjs | null>(dayjs(startTime || 0));
  const [endTimeLocal, setEndTimeLocal] = useState<Dayjs | null>(dayjs(endTime || 0));
  const [textLocal, setTextLocal] = useState(text || "");
  const {
    t
  } = useTranslation("common");
  useEffect(() => {
    setBusinessHourTimeText("startTime", startTimeLocal?.toDate()?.getTime());
  }, [startTimeLocal]);
  useEffect(() => {
    setBusinessHourTimeText("endTime", endTimeLocal?.toDate()?.getTime());
  }, [endTimeLocal]);
  useEffect(() => {
    setBusinessHourTimeText("text", textLocal);
  }, [textLocal]);
  return <Grid item xs={12}>
			<Paper style={{
      display: "flex",
      width: "100%",
      padding: "1rem 0.1rem"
    }}>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<TimePicker label={'label.openingTime'} onChange={newTime => {
            if (!newTime) return;
            setStartTimeLocal(newTime);
          }} value={startTimeLocal} sx={{
            width: "100%"
          }} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<TimePicker label={'label.closingTime'} onChange={newTime => {
            if (!newTime) return;
            setEndTimeLocal(newTime);
          }} value={endTimeLocal} sx={{
            width: "100%"
          }} />
					</Grid>
					<Grid item xs={12}>
						<TextField fullWidth minRows={2} name="description" value={textLocal} onChange={e => {
            setTextLocal(e?.target?.value);
          }} multiline placeholder={'placeholder.description.businessHoursTimeText'} InputProps={{
            endAdornment: <Tooltip describeChild title={t('tooltip.description.businessHoursTimeText')}>
									<IconButton>
										<HelpOutlineIcon />
									</IconButton>
								</Tooltip>
          }} />
					</Grid>
				</Grid>
				<IconButton onClick={deleteBusinessHourTimeText}>
					<ClearTwoToneIcon />
				</IconButton>
			</Paper>
		</Grid>;
}