import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { createStore, combineReducers, compose } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import {
	createFirestoreInstance,
	firestoreReducer,
	reduxFirestore,
} from "redux-firestore";

import { initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import historySlice from "features/historySlice";

const firebaseConfig = {
	apiKey: "AIzaSyCahMNOfbTAP_oz6_ySDe2IlBrf2S9cLI4",
	authDomain: "tourismapp-840ed.firebaseapp.com",
	projectId: "tourismapp-840ed",
	storageBucket: "tourismapp-840ed.appspot.com",
	messagingSenderId: "269905809648",
	appId: "1:269905809648:web:13199147bcd8bb37f6f235",
};

const firebaseConfigDev = {
	apiKey: "AIzaSyBTJ4Dva-2lFn2aHc9QPH_iYLnWUozCrGM",
	authDomain: "tourismapp-dev.firebaseapp.com",
	projectId: "tourismapp-dev",
	storageBucket: "tourismapp-dev.appspot.com",
	messagingSenderId: "153241713578",
	appId: "1:153241713578:web:f35d4bc7d5f7d6912fc9d3",
};

// react-redux-firebase config
const rrfConfig = {
	userProfile: "Parks",
	useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

const rfConfig = {};

const isProduction = process.env?.NODE_ENV === "production";

const app = initializeApp(isProduction ? firebaseConfig : firebaseConfigDev);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west3");
const storage = getStorage(app);
const auth = getAuth(app);

// Initialize firebase instance
firebase.initializeApp(isProduction ? firebaseConfig : firebaseConfigDev);

// Initialize other services on firebase instance
firebase.firestore(); // <- needed if using firestore

const createStoreWithFirebase = compose(
	reduxFirestore(firebase, rfConfig) // firebase instance as first argument, rfConfig as optional second
)(createStore);

// Add firebase to reducers
const rootReducer = combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer, // <- needed if using firestore
	history: historySlice,
});

// Create store with reducers and initial state
const initialState = {};
const store = createStoreWithFirebase(rootReducer, initialState);

const rrfProps = {
	firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance, // <- needed if using firestore
};

export { store, rrfProps, db, auth, functions, storage };
