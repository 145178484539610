import { Button, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemText, Menu, MenuItem, MenuList, Paper, Select, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { EmailTemplateSelect } from "components/common/ui/Selects/EmailTemplate-Select";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTranslation } from "react-i18next";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector } from "react-redux";
export function PriceGroupingItem({
  index,
  removeGrouping,
  handleFormikChangeInMap,
  handleFormikPriceIdChangeInMap,
  priceGroup,
  pricesSorted,
  activeTicketGrouping
}: {
  index: number;
  removeGrouping: any;
  handleFormikChangeInMap: any;
  handleFormikPriceIdChangeInMap: any;
  priceGroup: any;
  pricesSorted: any;
  activeTicketGrouping: any;
}) {
  const activeSeasonTicketsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTickets);
  const {
    t
  } = useTranslation("common");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuVisible = Boolean(anchorEl);
  function closeAnchor() {
    setAnchorEl(null);
  }
  function getTicketData(ticketId: string) {
    let returnVal;
    activeSeasonTicketsRedux.forEach((ticket: any) => {
      if (ticket.id !== ticketId) return;
      returnVal = ticket;
    });
    return returnVal;
  }
  return <Paper style={{
    // border: "1px solid black",
    margin: "0.5rem auto",
    padding: "0.5rem"
  }}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography typography={"h6"} align="center" style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5rem"
        }}>
                    {t('title.ticketPriceGroup', {
            x: index + 1
          })}
                    <div>
                        <IconButton onClick={event => {
              setAnchorEl(event.currentTarget);
            }}>
                            <MoreVertIcon />
                        </IconButton>

                        <Menu open={isMenuVisible} anchorEl={anchorEl} onClose={closeAnchor}>
                            <MenuList>
                                {/* <MenuItem
                                 onClick={() => {
                                    // openEditPrice(price?.id);
                                 }}
                                 >
                                 Duplicate
                                 </MenuItem> */}
                                <MenuItem onClick={() => {
                  removeGrouping(index);
                }}>
                                    Remove
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </div>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth
        // error={formik.touched.minVisitors && Boolean(formik.errors.minVisitors)}
        label={t('label.minVisitors')} placeholder={"1"} size="small" type="number" value={priceGroup?.minVisitors} onChange={e => {
          handleFormikChangeInMap(index, "minVisitors", Number(e?.target?.value));
        }} required />
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth
        // error={formik.touched.minVisitors && Boolean(formik.errors.minVisitors)}
        label={t('label.maxVisitors')} placeholder={"1"} size="small" type="number" value={priceGroup?.maxVisitors} onChange={e => {
          handleFormikChangeInMap(index, "maxVisitors", Number(e?.target?.value));
        }} required />
            </Grid>
            <Grid item xs={12}>
                <hr />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth
        // error={formik.touched.minVisitors && Boolean(formik.errors.minVisitors)}
        label={t('label.ticketPriceGroup.autoCloseTicketAtMinVisitors')} placeholder={"1"} size="small" type="number" value={priceGroup?.maxVisitorsClosingTicket} onChange={e => {
          handleFormikChangeInMap(index, "maxVisitorsClosingTicket", Number(e?.target?.value));
        }} required InputProps={{
          endAdornment: <Tooltip describeChild title={t('tooltip.ticketPriceGroup.autoCloseTicketAtMinVisitors')}>
                                <IconButton>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
        }} />
            </Grid>
            <Grid item xs={12}>
                <hr />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth
        // error={formik.touched.minVisitors && Boolean(formik.errors.minVisitors)}
        label={t('label.webhookUrl')} placeholder={"https://example.com/webhook"} size="small" type="text" value={priceGroup?.webhook || ""} onChange={e => {
          handleFormikChangeInMap(index, "webhook", String(e?.target?.value));
        }} InputProps={{
          endAdornment: <Tooltip describeChild title={t('tooltip.webhookUrl')}>
                                <IconButton>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
        }} />
            </Grid>
            <Grid item xs={12}>
                <EmailTemplateSelect setTemplate={(data: any) => {
          handleFormikChangeInMap(index, "emailTemplateId", String(data));
        }} defaultValue={priceGroup?.emailTemplateId || ""} key={`Email Template ${priceGroup?.emailTemplateId}`} />
            </Grid>
            <Grid item xs={12}>
                <hr />
            </Grid>
            <Grid item xs={12}>
                <PricingSelect title={t('label.additionalPrice') || ""} value={priceGroup?.extraPriceId || ""} onChange={(data: any) => {
          handleFormikChangeInMap(index, "extraPriceId", String(data));
        }}>
                    {pricesSorted?.map((price: any) => {
            return <MenuItem value={price?.id}>
                                <b>{price?.Title}</b>:{" "}
                                {price?.Price}€
                            </MenuItem>;
          })}
                </PricingSelect>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {activeTicketGrouping?.TicketIds?.map((ticketId: string, priceIndex: number) => {
            const ticket: any = getTicketData(ticketId);
            return <ListItem>
                                    <ListItemText>
                                        {ticket?.Title}
                                    </ListItemText>
                                    <PricingSelect value={priceGroup?.priceIds[priceIndex]?.priceId || ""} onChange={(data: any) => {
                handleFormikPriceIdChangeInMap(index, {
                  ticketId: ticketId,
                  priceId: data
                }, priceIndex);
              }}>
                                        {pricesSorted?.map((price: any) => {
                  return <MenuItem value={price?.id}>
                                                        <b>
                                                            {price?.Title}
                                                        </b>
                                                        :{" "}
                                                        {price?.Price}
                                                        €
                                                    </MenuItem>;
                })}
                                    </PricingSelect>
                                </ListItem>;
          })}
                </List>
            </Grid>
        </Grid>
    </Paper>;
}
function PricingSelect({
  children,
  value,
  onChange,
  title = "Pricing"
}: {
  children?: any;
  value: string;
  onChange: any;
  title?: string;
}) {
  const {
    t
  } = useTranslation("common");
  return <FormControl fullWidth>
            <InputLabel id="pricing-select-label">{title}</InputLabel>
            <Select labelId="pricing-select-label" id="pricing-select" value={value} label={t('label.pricing')} onChange={(e: any) => {
      onChange(e?.target?.value);
    }}>
                {children}
            </Select>
        </FormControl>;
}