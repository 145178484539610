import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { LoadingSpinnerModal } from "../Loading-Spinner/loading-spinner-modal";
export function FormDialogLayout({
  isOpen,
  title,
  closeFunction,
  submitFunction,
  children,
  loading = false,
  submitButtonText = "Update",
  leftButton
}: {
  isOpen: boolean;
  title: string;
  closeFunction: any;
  submitFunction: any;
  children?: any;
  loading?: boolean;
  submitButtonText?: string;
  leftButton?: any;
}) {
  return <Dialog open={isOpen} onClose={closeFunction}>
			<LoadingSpinnerModal loading={loading} />
			<DialogTitle variant="h2" style={{
      display: "flex",
      justifyContent: "space-between",
      padding: "0.5rem"
    }}>
				{title}
				<IconButton onClick={closeFunction}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent style={{
      padding: "1rem"
    }}>
				<form onSubmit={submitFunction}>
					{children}
					<DialogActions>
						<div style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}>
							{leftButton}
							<Button color="success" variant="contained" type="submit" disabled={loading} fullWidth>
								{submitButtonText}
							</Button>
						</div>
					</DialogActions>
				</form>
			</DialogContent>
		</Dialog>;
}