import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { getLocaleDateString } from "../../../../base/Calendars/Business-Hours/lib/date-helper";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../utilities/Firebase/firebase-redux";
const today = dayjs();
export default function CurrentlyOpen({
  parkId
}: {
  parkId: any;
}) {
  const [businessHours, setBusinessHours] = useState<any>(null);
  const [now, setNow] = useState<number>(Date.now());
  const [timeText, setTimeText] = useState<any>("Einen Moment bitte, die heutige, aktuelle Öffnungszeit, wird Ihnen gleich angezeigt.");
  useEffect(() => {
    if (!parkId) return;
    const dateString = getLocaleDateString(today?.toDate()?.getTime() || 0);
    const ref = collection(db, `/Parks/${parkId}/Business-Hours`);
    const unsubscribe = onSnapshot(ref, (res: any) => {
      let bHours: any = {};
      res.docs.forEach((docu: any) => {
        if (docu?.id !== dateString) return;
        const data = docu.data();
        data.id = docu.id;
        bHours = data;
      });
      setBusinessHours(bHours);
    }, (err: any) => {
      console.error({
        err
      });
    });
  }, [now]);
  useEffect(() => {
    if (!businessHours?.TimeTexts) return;
    var timings: string = "Wir haben heute geschlossen";
    businessHours?.TimeTexts?.forEach((timeText: any) => {
      if (now >= timeText?.Start?.DateTime && now <= timeText?.End?.DateTime) timings = timeText?.Text;
    });
    setTimeText(timings);
  }, [businessHours, now]);
  useEffect(() => {
    setInterval(() => {
      setNow(Date.now());
    }, 5000);
  }, []);
  return <span style={{
    margin: "auto"
  }}>{timeText}</span>;
}