import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDocs,
	setDoc,
} from "firebase/firestore";
import { db } from "utilities/Firebase/firebase-redux";

export async function updateSeasonFirebase(
		newData: any,
		uid: string,
		activeSeason: string
) {
	const ref = doc(db, `/Parks/${uid}/Seasons/${activeSeason}`);
	return await setDoc(ref, newData, { merge: true });
}

export async function addBusinessHourFirebase(
		newData: any,
		uid: string,
		id: string
) {
	const ref = doc(db, `/Parks/${uid}/Business-Hours/${id}`);
	return await setDoc(ref, newData, { merge: true });
}

export async function updateBusinessHourFirebase(
		newData: any,
		uid: string,
		id: string
) {
	const ref = doc(db, `/Parks/${uid}/Business-Hours/${id}`);
	return await setDoc(ref, newData, { merge: true });
}

export async function deleteBusinessHourFirebase(uid: string, id: string) {
	const ref = doc(db, `/Parks/${uid}/Business-Hours/${id}`);
	return await deleteDoc(ref);
}

export async function addOldBusinessHourFirebase(
		newData: any,
		uid: string,
		id: string
) {
	const ref = collection(db, `/Parks/${uid}/Business-Hours/${id}/old`);
	return await addDoc(ref, newData);
}

export async function getOldBusinessDayData(uid: string, id: string) {
	const ref = collection(db, `/Parks/${uid}/Business-Hours/${id}/old`);
	return await getDocs(ref);
}
