import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { doc, getDoc } from "firebase/firestore";
import TodayOpen from "components/common/components/iFrames/Today-Open";
import { db } from "utilities/Firebase/firebase-redux";
export function TodayOpenPage() {
  const [businessHours, setBusinessHours] = useState<any[]>([]);
  const [parkId, setParkId] = useState<string | undefined>("");
  const params = useParams();
  useEffect(() => {
    const parkId = params?.parkid;
    setParkId(parkId);
  }, [params]);
  useEffect(() => {
    if (!parkId || parkId === "") return;
    getBusinessHoursForMyParkFromFirestore(parkId).then(res => {
      let data: any = res.data();
      data.id = res.get;
      setBusinessHours(data?.BusinessHours);
    });
  }, [parkId]);
  async function getBusinessHoursForMyParkFromFirestore(id: string) {
    const myDoc = doc(db, `/BusinessHours/${id}`);
    return await getDoc(myDoc);
  }
  return <div style={{
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignContent: 'center',
    alignItems: "center",
    justifyContent: 'center'
  }}>
        <TodayOpen businessHours={businessHours} />
    </div>;
}