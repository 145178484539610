import { Grid } from "@mui/material";
import BasicInformation from "../../../../components/base/Settings/General-Information/basic-information";
import { PrivatePageLayout } from "components/common/layouts/Page/private";
import ContactInformationComponent from "components/base/Settings/General-Information/contact-information";
import ParkDescriptionComponent from "components/base/Settings/General-Information/park-description";
import ParkLocation from "components/base/Settings/General-Information/park-location";
import ProfileInformation from "components/base/Settings/General-Information/profile-information";
import SocialMediaList from "components/base/Settings/General-Information/social-media";
import ParkShownImages from "components/base/Settings/General-Information/park-shown-images";
import MetaDescriptioneSettingsAccordion from "components/base/Settings/General-Information/meta-description";
import FAQSettingsAccordion from "components/base/Settings/General-Information/faqs";
import { useTranslation } from "react-i18next";
export function GeneralInformationSettingsPage() {
  const {
    t
  } = useTranslation("common");
  return <PrivatePageLayout link={"https://images.pexels.com/photos/2686558/pexels-photo-2686558.jpeg?auto=compress&cs=tinysrgb"} activeLink={"General Information"}
  // activeSubLink={"General Information"}
  title={t("title.generalInformation")}>
			<Grid container style={{
      maxWidth: "500px",
      margin: "auto"
    }} spacing={0}>
				<Grid item xs={12}>
					<BasicInformation />
				</Grid>
				<Grid item xs={12}>
					<ContactInformationComponent />
				</Grid>
				<Grid item xs={12}>
					<ParkDescriptionComponent />
				</Grid>
				<Grid item xs={12}>
					<MetaDescriptioneSettingsAccordion />
				</Grid>
				<Grid item xs={12}>
					<FAQSettingsAccordion />
				</Grid>
				<Grid item xs={12}>
					<ParkLocation />
				</Grid>
				<Grid item xs={12}>
					<ParkShownImages />
				</Grid>
				<Grid item xs={12}>
					<ProfileInformation />
				</Grid>
				<Grid item xs={12}>
					<SocialMediaList />
				</Grid>
			</Grid>
		</PrivatePageLayout>;
}