import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
	pathname: "",
	search: "",
};

export const historySlice = createSlice({
	name: "historySlice",
	initialState,
	reducers: {
		setPathnameRedux: (state, action: any) => {
			state.pathname = action.payload;
		},
		setSearchRedux: (state, action: any) => {
			state.search = action.payload;
		},
	},
});

export const {setPathnameRedux, setSearchRedux} = historySlice.actions;

export default historySlice.reducer;
