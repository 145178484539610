import Registration from "components/base/Registration";
export default function RegistrationPage() {
  return <div style={{
    width: "100vw",
    height: "100vh",
    display: 'flex',
    backgroundImage: "url('https://images.pexels.com/photos/2730355/pexels-photo-2730355.jpeg?auto=compress&cs=tinysrgb')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center center'
  }}>
        <Registration />
    </div>;
}