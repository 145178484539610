import { Grid, TextField, Button, Checkbox, Typography } from "@mui/material";
import { PasswordField } from "components/common/ui/TextFields/Password-Field";
import { browserSessionPersistence, inMemoryPersistence, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { auth } from "utilities/Firebase/firebase-redux";
export default function LoginForm() {
  const historyRedux = useSelector((state: any) => state.history);
  const navigate = useNavigate();
  const {
    t
  } = useTranslation("common");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isSaveLogInSelected, setSaveLogInIsSelected] = useState(false);
  function login(e: any) {
    e.preventDefault();
    setPersistence(auth, isSaveLogInSelected ? browserSessionPersistence : inMemoryPersistence).then(() => {
      signInWithEmailAndPassword(auth, email, password).then(res => {
        toast.success(t("success.logIn"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        const oldPath = `${historyRedux?.pathname}${historyRedux?.search}`;
        navigate(oldPath === "" ? "/" : oldPath);
      }).catch(err => {
        toast.error(t("error.logIn"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
    }).catch(err => {
      toast.error(t("error.settingPersistence"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    });
  }
  return <form style={{
    margin: "auto"
  }} onSubmit={login}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField variant="standard" required type="email" label={t("label.emailAddress")} fullWidth value={email} onChange={(e: any) => {
          setEmail(e.target?.value);
        }} />
				</Grid>
				<Grid item xs={12}>
					<PasswordField setPassword={setPassword} label={t("label.password")} required />
				</Grid>
				<Grid item xs={12}>
					<Typography onClick={() => {
          setSaveLogInIsSelected(!isSaveLogInSelected);
        }} style={{
          margin: 0,
          textAlign: "start"
        }}>
						<Checkbox checked={isSaveLogInSelected} />{" "}
						{t("label.stayLoggedIn")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth type="submit" variant="contained">
						{t("button.logIn")}
					</Button>
				</Grid>
			</Grid>
		</form>;
}