import { BottomNavigation, BottomNavigationAction, Button, Divider, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormDialogLayout } from "components/common/layouts/Dialog/form-dialog-layout";
import { useFormik } from "formik";
import { updateTimingGroupDoc } from "../../lib/firebase-helper";
import { MuiColorInput } from "mui-color-input";
import { TimeTicketGroupingItem } from "./time-ticket-grouping-item";
import { BusinessHoursTimeText } from "./business-hours-time-text";
import { useTranslation } from "react-i18next";
export type TimingGroupType = {
  title: string;
  timings: any[];
  color: string;
  businessHoursTimeTexts: any[];
};
export default function EditTimingGroupDialog({
  visible,
  closeFunction,
  id,
  deleteTicketFunction
}: {
  visible: boolean;
  closeFunction: any;
  id: string | null;
  deleteTicketFunction: any;
}) {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);
  const activeSeasonTimingGroupsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTimingGroups);
  const [isLoading, setIsLoading] = useState(false);
  const {
    t
  } = useTranslation("common");
  const formik = useFormik<TimingGroupType>({
    initialValues: {
      title: "",
      timings: [],
      color: "",
      businessHoursTimeTexts: []
    },
    // validationSchema: PersonalInformationSchema,
    onSubmit: updateTiming
  });
  useEffect(() => {
    if (!id) return;
    activeSeasonTimingGroupsRedux?.forEach((ticketData: any) => {
      if (ticketData.id !== id) return;
      formik.setFieldValue("title", ticketData?.Title || "");
      formik.setFieldValue("timings", ticketData?.Timings || []);
      formik.setFieldValue("businessHoursTimeTexts", ticketData?.BusinessHoursTimeTexts || []);
      formik.setFieldValue("color", ticketData?.Color || "");
    });
  }, [activeSeasonTimingGroupsRedux, id]);
  function updateTiming(values: TimingGroupType) {
    if (!id) return;
    setIsLoading(true);
    const newData = {
      Title: values.title,
      Timings: values.timings,
      Color: values.color,
      BusinessHoursTimeTexts: values.businessHoursTimeTexts,
      UpdatedOn: Date.now()
    };
    updateTimingGroupDoc(id, newData, uid, activeSeasonRedux[0]?.id).then(() => {
      setIsLoading(false);
      toast.success("Updated Time Group");
      closeFunction();
    }).catch(error => {
      setIsLoading(false);
      toast.error("Error while updating Time Group");
      console.log({
        error
      });
    });
  }
  function addNewBusinessHourTimeText() {
    const newArr = structuredClone(formik.values.businessHoursTimeTexts);
    newArr.push({
      startTime: 0,
      endTime: 0,
      text: ""
    });
    formik.setFieldValue("businessHoursTimeTexts", newArr);
  }
  function handleFormikChangeInBusinessHoursTimeTexts(timingIndex: number, field: string, value: number | string) {
    const newArr = structuredClone(formik.values.businessHoursTimeTexts);
    const nowObj: any = newArr[timingIndex];
    nowObj[field] = value;
    formik.setFieldValue("businessHoursTimeTexts", newArr);
  }
  function deleteBusinessHourTimeText(index: number) {
    const newArr: any[] = structuredClone(formik.values.businessHoursTimeTexts);
    newArr?.splice(index, 1);
    formik.setFieldValue("businessHoursTimeTexts", newArr);
  }
  function addNewTicketTimeGrouping() {
    const newArr = structuredClone(formik.values.timings);
    newArr.push({
      startTime: 0,
      endTime: 0,
      ticketGroupIds: []
    });
    formik.setFieldValue("timings", newArr);
  }
  function handleFormikChangeInTimings(timingIndex: number, field: string, value: number) {
    const newArr = structuredClone(formik.values.timings);
    const nowObj: any = newArr[timingIndex];
    nowObj[field] = value;
    formik.setFieldValue("timings", newArr);
  }
  function deleteTicketTimeGrouping(index: number) {
    const newArr: any[] = structuredClone(formik.values.timings);
    newArr?.splice(index, 1);
    formik.setFieldValue("timings", newArr);
  }
  const [selectedTab, setSelectedTab] = useState("general");
  return <FormDialogLayout isOpen={visible} title={t('title.editTimeGroup')} closeFunction={closeFunction} leftButton={<Button fullWidth variant="contained" color="error" onClick={deleteTicketFunction}>
				{t('button.delete')}
			</Button>} submitFunction={formik.handleSubmit} loading={isLoading}>
			<BottomNavigation showLabels value={selectedTab} onChange={(event, newValue) => {
      setSelectedTab(newValue);
    }}>
				<BottomNavigationAction label={t('button.general')} value={"general"} />
				<BottomNavigationAction label={t('button.ticketTimeGroups')} value={"ticket-time-groups"} />
				<BottomNavigationAction label={t('button.businessHourTexts')} value={"business-hours-texts"} />
			</BottomNavigation>
			{selectedTab === "general" && <Grid container>
				<Grid item xs={12} style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center"
      }}>
					<TextField required fullWidth error={formik.touched.title && Boolean(formik.errors.title)} label={t('label.title')} placeholder={t('placeholder.title') || ""} size="small" value={formik.values.title} onChange={formik.handleChange} name="title" />
				</Grid>
				<Grid item xs={12} style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center"
      }}>
					<MuiColorInput required fullWidth error={formik.touched.color && Boolean(formik.errors.color)} size="small" label="Farbe" name="color" value={formik.values.color} onChange={(color: string) => {
          formik.setFieldValue("color", color);
        }} />
				</Grid>
			</Grid>}
			{selectedTab === "ticket-time-groups" && <Grid container spacing={1}>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={12} style={{
            textAlign: "center"
          }}>
							<Button onClick={addNewTicketTimeGrouping}>
								{t('button.addNewTicketTimeGroup')}
							</Button>
						</Grid>
						{formik.values.timings?.map((ticketTimeGroup, index) => {
            return <TimeTicketGroupingItem key={`Time Ticket ${index} ${formik.values.timings?.length}`} startTime={ticketTimeGroup?.startTime} endTime={ticketTimeGroup?.endTime} tickets={ticketTimeGroup?.ticketGroupIds || []} setTime={(field: string, value: number) => {
              handleFormikChangeInTimings(index, field, value);
            }} setTickets={(value: any) => {
              handleFormikChangeInTimings(index, "ticketGroupIds", value);
            }} deleteTimeGroup={() => {
              deleteTicketTimeGrouping(index);
            }} />;
          })}
					</Grid>
				</Grid>
			</Grid>}
			{selectedTab === "business-hours-texts" && <Grid container>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={12} style={{
            textAlign: "center"
          }}>
							<Button onClick={addNewBusinessHourTimeText}>
								{t('button.addNewBusinessHourText')}
							</Button>
						</Grid>
						{formik.values.businessHoursTimeTexts?.map((businessHourTimeText, index) => {
            return <BusinessHoursTimeText key={`Time Text ${index} ${formik.values.businessHoursTimeTexts?.length}`} startTime={businessHourTimeText?.startTime} endTime={businessHourTimeText?.endTime} text={businessHourTimeText?.text} setBusinessHourTimeText={(field: string, value: number | number) => {
              handleFormikChangeInBusinessHoursTimeTexts(index, field, value);
            }} deleteBusinessHourTimeText={() => {
              deleteBusinessHourTimeText(index);
            }} />;
          })}
					</Grid>
				</Grid>
			</Grid>}
		</FormDialogLayout>;
}