import { IconButton, Menu, MenuList, Paper, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useRef, useState } from "react";
export function SettingsListItem({
  title,
  editFunction,
  buttonText = "Edit",
  children
}: {
  title: string;
  editFunction?: any;
  buttonText?: string;
  children?: any;
}) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  return <Paper elevation={3} style={{
    maxWidth: "500px",
    margin: "1rem auto",
    borderRadius: "1rem",
    padding: "0.5rem",
    minWidth: "150px"
  }}>
			<div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center'
    }}>
				<Typography typography={"h3"}>
					{title}
				</Typography>
				<div>
					<IconButton onClick={event => {
          setAnchorEl(event.currentTarget);
        }}>
						<MoreVertIcon />
					</IconButton>

					<Menu open={open} anchorEl={anchorEl} onClose={() => {
          setAnchorEl(null);
        }}>
						<MenuList>
							{children}
						</MenuList>
					</Menu>
				</div>
			</div>
		</Paper>;
}