import { Avatar, Button, IconButton, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { onAuthStateChanged, updateProfile } from "firebase/auth";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import MediaLibraryDialog from "components/base/Dialogs/Media-Library-Dialog";
import { auth } from "utilities/Firebase/firebase-redux";
export default function ProfileInformation() {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const [displayName, setDisplayName] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [isMediaLibraryOpen, setMediaLibraryIsOpen] = useState(false);
  useEffect(() => {
    onAuthStateChanged(auth, user => {
      setProfilePicture(user?.photoURL || "");
      setDisplayName(user?.displayName || "");
    });
  }, [auth]);
  function submitHandler(event: any) {
    event.preventDefault();
    if (!uid || !auth?.currentUser) {
      toast.error("Not logged in");
      return;
    }
    updateProfile(auth.currentUser, {
      displayName: displayName,
      photoURL: profilePicture
    }).then(res => {
      toast.success("Profile Information updated");
    }).catch(err => {
      toast.error("Profile Information Update Error");
    });
  }
  function closeMediaLibraryFunction() {
    setMediaLibraryIsOpen(false);
  }
  return <AccordionCustomComponent title={"Profile Information"}>
			<MediaLibraryDialog isOpen={isMediaLibraryOpen} closeFunction={closeMediaLibraryFunction} imageOnClickFunction={(url: string) => {
      setProfilePicture(url);
      closeMediaLibraryFunction();
    }} />
			<form onSubmit={submitHandler}>
				<div style={{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center"
      }}>
					<IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => {
          setMediaLibraryIsOpen(true);
        }} color="inherit">
						<Avatar src={profilePicture} />
					</IconButton>
					{/* <Avatar src={profilePicture} onClick={()=>{setMediaLibraryIsOpen(true)}} style={{marginRight: "0.5rem"}}/> */}
					<TextField required fullWidth label="Display Name" size="small" value={displayName} onChange={e => {
          setDisplayName(e?.target?.value);
        }} />
				</div>
				<Button variant="contained" fullWidth type="submit">
					Update
				</Button>
			</form>
		</AccordionCustomComponent>;
}