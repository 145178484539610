import { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router";
import CurrentlyOpen from "components/common/components/iFrames/Currently-Open";
import dayjs from "dayjs";
import { getLocaleDateString } from "components/base/Calendars/Business-Hours/lib/date-helper";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../utilities/Firebase/firebase-redux";
// import { getBusinessHourDataFromPark } from "../Calendar";

// const today = dayjs();

export function CurrentlyOpenPage() {
  // const [businessHours, setBusinessHours] = useState<any>(null);
  const [parkId, setParkId] = useState<string | undefined>("");
  const params = useParams();

  // const [localeDateString, setLocaleDateString] = useState("")

  // useEffect(() => {
  //     const dateString = getLocaleDateString(today?.toDate()?.getTime() || 0);
  //     setLocaleDateString(dateString)
  // }, [today])

  useEffect(() => {
    if (!params?.parkid) return;
    const parkId = params?.parkid;
    setParkId(parkId);
  }, [params]);

  // useEffect(() => {
  //     if (!parkId) return;
  //     const dateString = getLocaleDateString(today?.toDate()?.getTime() || 0);
  //
  //     getBusinessHourDataFromPark(parkId)
  //         .then((res) => {
  //             let bHours: any = {};
  //             res.docs.forEach((docu) => {
  //                 if (docu?.id !== dateString) return;
  //                 const data = docu.data();
  //                 data.id = docu.id;
  //                 bHours = data;
  //             })
  //             setBusinessHours(bHours);
  //         })
  //         .catch((err: any) => {
  //             console.log({ err })
  //         })
  // }, [parkId])

  return <div style={{
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignContent: 'center',
    alignItems: "center",
    justifyContent: 'center'
  }}>
        <Suspense>
            <CurrentlyOpen parkId={parkId} />
        </Suspense>
    </div>;
}