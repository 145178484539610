import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { UploadButton } from "../../ui/Buttons/Upload-Button";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
export function MediaList({
  imageOnClickFunction
}: {
  imageOnClickFunction: any;
}) {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const {
    t
  } = useTranslation();
  const [mediaLinks, setMediaLinks] = useState<string[]>([]);
  const storage = getStorage();
  useEffect(() => {
    if (!uid) return;
    const parkFileRef = ref(storage, `/Parks/${uid}`);
    getFiles(parkFileRef);
  }, [uid]);
  async function getFiles(parkFileRef: any) {
    const itemList = await listAll(parkFileRef);
    const items = itemList.items;
    const links: string[] = [];
    items?.forEach(async itemRef => {
      const link = await getDownloadURL(itemRef);
      links.push(link);
      setMediaLinks([...links]);
    });
  }
  return <div style={{
    padding: "0.5rem"
  }}>
			<Grid container spacing={1}>
				{mediaLinks?.sort((a: string, b: string) => {
        return a.localeCompare(b);
      })?.map(link => {
        return <Grid item xs={6} sm={4} md={3} lg={2} xl={1} style={{
          padding: "0.5rem",
          maxHeight: "100px",
          textAlign: "center"
        }}>
								<img onClick={() => {
            imageOnClickFunction(link);
          }} src={link} alt="" style={{
            border: "1px solid black",
            maxWidth: "100%",
            maxHeight: "100%",
            padding: "0.1rem"
          }} />
							</Grid>;
      })}
			</Grid>
			<UploadButton label={t("label.uploadNewFile")} location={`/Parks/${uid}/`} setLink={(newLink: string) => {
      const parkFileRef = ref(storage, `/Parks/${uid}`);
      getFiles(parkFileRef);
    }} />
		</div>;
}