import { Autocomplete, Button, Checkbox, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormDialogLayout } from "components/common/layouts/Dialog/form-dialog-layout";
import { useFormik } from "formik";
import { updateTicketGroupDoc } from "../../lib/firebase-helper";
import MediaLibraryDialog from "components/base/Dialogs/Media-Library-Dialog";
import { TimingSelect } from "./components/timing-select";
import { MuiColorInput } from "mui-color-input";
export type MaxTimeType = {
  isDateSelected: boolean;
  date: {
    days: number;
    dateTime: number;
  };
  hours: number;
};
type TicketGroupType = {
  image: string;
  title: string;
  ticketIds: string[];
  description: string;
  cancellation: MaxTimeType;
  confirmation: MaxTimeType;
  color: string;
};
export const defaultMaxTime: MaxTimeType = {
  isDateSelected: false,
  date: {
    days: 0,
    dateTime: 0
  },
  hours: 0
};
export default function EditTicketGroupDialog({
  visible,
  closeFunction,
  id,
  deleteTicketFunction
}: {
  visible: boolean;
  closeFunction: any;
  id: string | null;
  deleteTicketFunction: any;
}) {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);
  const activeSeasonTicketGroupingsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTicketsGroups);
  const activeSeasonTicketsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTickets);
  const [isMediaLibraryOpen, setMediaLibraryIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cancellationKey, setCancellationKey] = useState(0);
  const [confirmationKey, setConfirmationKey] = useState(0);
  const formik = useFormik<TicketGroupType>({
    initialValues: {
      image: "",
      title: "",
      ticketIds: [],
      cancellation: structuredClone(defaultMaxTime),
      confirmation: structuredClone(defaultMaxTime),
      description: "",
      color: ""
    },
    onSubmit: updateTicket
  });
  useEffect(() => {
    if (!id) return;
    activeSeasonTicketGroupingsRedux?.forEach((ticketData: any) => {
      if (ticketData.id !== id) return;
      const cancellation = structuredClone(ticketData?.Cancellation);
      const confirmation: MaxTimeType = structuredClone(ticketData?.Confirmation);
      formik.setFieldValue("title", ticketData?.Title || "");
      formik.setFieldValue("description", ticketData?.Description || "");
      formik.setFieldValue("image", ticketData?.Image || "");
      formik.setFieldValue("ticketIds", ticketData?.TicketIds || []);
      formik.setFieldValue("color", ticketData?.Color || "");
      setConfirmation(confirmation);
      setCancellation(cancellation);
    });
  }, [activeSeasonTicketGroupingsRedux, id]);
  function updateTicket(values: TicketGroupType) {
    if (!id) return;
    setIsLoading(true);
    const newData = {
      Title: values.title,
      Description: values.description,
      Image: values.image,
      TicketIds: values.ticketIds,
      Cancellation: values.cancellation,
      Confirmation: values.confirmation,
      UpdatedOn: Date.now(),
      Color: values.color
    };
    updateTicketGroupDoc(id, newData, uid, activeSeasonRedux[0]?.id).then(() => {
      setIsLoading(false);
      toast.success("Updated Ticket Group");
      closeFunction();
    }).catch(error => {
      setIsLoading(false);
      toast.error("Error while updating Ticket");
      console.log({
        error
      });
    });
  }
  function openMediaLibraryFunction() {
    setMediaLibraryIsOpen(true);
  }
  function closeMediaLibraryFunction() {
    setMediaLibraryIsOpen(false);
  }
  function imageOnClickFunction(src: string) {
    formik.setFieldValue("image", src);
    closeMediaLibraryFunction();
  }

  // function doesObjExistInArray(obj: any, array: any[]) {
  // 	let returnVal = false;

  // 	array.forEach((arrObj) => {
  // 		if (obj?.id !== arrObj?.id) return;

  // 		returnVal = true;
  // 	})

  // 	return returnVal;
  // }

  function setConfirmation(newData: MaxTimeType) {
    formik.setFieldValue("confirmation", newData);
    setConfirmationKey(confirmationKey + 1);
  }
  function setCancellation(newData: MaxTimeType) {
    formik.setFieldValue("cancellation", newData);
    setCancellationKey(cancellationKey + 1);
  }
  function findTicketFromTicketId(ticketId: string) {
    var returnOption: any = {};
    activeSeasonTicketsRedux?.forEach((ticket: any) => {
      if (ticket?.id !== ticketId) return;
      returnOption = ticket;
    });
    return returnOption;
  }
  function getOptionLabel(option: any) {
    const ticketData = findTicketFromTicketId(option);
    return `${ticketData?.Title}`;
  }
  function onChangeTickets(event: any, options: any, eventType: string, option: any) {
    const ticketId = option?.option?.id;
    if (!ticketId) return;
    const newArr: any[] = structuredClone(formik.values.ticketIds);
    const optionIndex = newArr.indexOf(ticketId);
    if (optionIndex >= 0) newArr.splice(optionIndex, 1);else newArr.push(ticketId);
    formik.setFieldValue("ticketIds", newArr);
  }
  return <FormDialogLayout isOpen={visible} title={"Edit Ticket Grouping"} closeFunction={closeFunction} leftButton={<Button fullWidth variant="contained" color="error" onClick={deleteTicketFunction}>
				Delete
			</Button>} submitFunction={formik.handleSubmit} loading={isLoading}>
			<MediaLibraryDialog isOpen={isMediaLibraryOpen} closeFunction={closeMediaLibraryFunction} imageOnClickFunction={imageOnClickFunction} />

			<Grid container spacing={1}>
				<Grid item xs={12} style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center"
      }}>
					<TextField required fullWidth error={formik.touched.title && Boolean(formik.errors.title)} label={"Title"} placeholder={"Name this Ticket Group"} size="small" value={formik.values.title} onChange={formik.handleChange} name="title" />
				</Grid>
				<Grid item xs={12}>
					<MuiColorInput size="small" name="color" value={formik.values.color} onChange={formik.handleChange} label="Farbe" fullWidth />
				</Grid>
				<Grid item xs={12}>
					<TextField fullWidth minRows={5} placeholder="Description" name="description" value={formik.values.description} onChange={formik.handleChange} multiline />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TimingSelect title={"Stornierungszeit"} values={formik.values.cancellation} onChange={setCancellation} name={"cancellation"} key={`Cancellation Select ${cancellationKey}`} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TimingSelect title={"Reservierungszeit"} values={formik.values.confirmation} onChange={setConfirmation} name={"confirmation"} key={`Confirmation Select ${confirmationKey}`} />
				</Grid>
				<Grid item xs={12}>
					<Autocomplete fullWidth multiple id="tickets" onChange={onChangeTickets}
        //@ts-ignore
        value={formik?.values?.ticketIds || []} options={activeSeasonTicketsRedux} disableCloseOnSelect getOptionLabel={getOptionLabel} renderOption={(props, option: any, {
          selected
        }) => {
          return <li {...props} key={`Time Ticket Checkbox Item ${option?.id}`}>
									<Checkbox style={{
              marginRight: 8
            }} checked={formik.values.ticketIds?.includes(option.id)} />
									{option?.Title}
								</li>;
        }} style={{
          width: "100%"
        }} renderInput={params => <TextField {...params} label="Tickets" placeholder="" fullWidth />} />
				</Grid>
				<Grid item xs={12}>
					<img src={formik.values.image} style={{
          width: "100%",
          minHeight: "100px",
          minWidth: "100px"
        }} />
					<br />
					<Button onClick={openMediaLibraryFunction}>
						Select new Image
					</Button>
				</Grid>
			</Grid>
		</FormDialogLayout>;
}