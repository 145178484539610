import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
export default function AreYouSureComponent({
  isOpen,
  title,
  noFunction = () => {},
  yesFunction = () => {}
}: {
  isOpen: boolean;
  title: string;
  noFunction?: any;
  yesFunction?: any;
}) {
  return <Dialog open={isOpen}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent></DialogContent>
			<DialogActions>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<Button fullWidth variant="contained" onClick={noFunction}>
							No
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button color="error" fullWidth variant="contained" onClick={yesFunction}>
							Yes
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>;
}