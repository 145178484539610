import { block as _block$ } from "million/react";
import { Table, TableCell, TableHead, TableRow, Typography } from "@mui/material";
const M$ExportFile = function ExportFile({
  localeDateString,
  events
}: {
  localeDateString: string;
  events: any[];
}) {
  return <div style={{
    width: "100%",
    height: "100%"
  }}>
			<div style={{
      display: "flex",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "center"
    }}>
				<img src="" alt="KW Staufen" />
				<Typography typography={"h1"}>{localeDateString}</Typography>
				<img src="" alt="TouriTickets" />
			</div>
			<div>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Uhrzeit</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Ticket</TableCell>
							<TableCell>Besucher</TableCell>
							<TableCell>Preis</TableCell>
						</TableRow>
					</TableHead>
					{/* <TableBody>
                    {events.map((row) => {
                        console.log({ row })
                        return <TableRow key={`${row?.name}`}>
                            <TableCell>
                             </TableCell>
                        </TableRow>
                    })}
                </TableBody> */}
				</Table>
			</div>
		</div>;
};
const ExportFile = M$ExportFile;
export { ExportFile };