import { CloseOutlined } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { MediaList } from "components/common/components/Media-List";
export default function MediaLibraryDialog({
  isOpen,
  closeFunction,
  imageOnClickFunction
}: {
  isOpen: boolean;
  closeFunction: any;
  imageOnClickFunction: any;
}) {
  return <Dialog open={isOpen}>
			<DialogTitle variant="h2" style={{
      display: "flex",
      justifyContent: "space-between",
      padding: "0.5rem"
    }}>
				Media
				<IconButton onClick={closeFunction}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<MediaList imageOnClickFunction={imageOnClickFunction} />
			</DialogContent>
		</Dialog>;
}