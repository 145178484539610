// import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { store } from "./utilities/Firebase/firebase-redux";
import { DashboardPage, EventCalendarPage, MediaLibraryPage } from "./pages/private";
import { LoginPageBlock, PasswordResetPage } from "./pages/public";
import { CurrentlyOpenPage, IFrameCalendarPage, TodayOpenPage } from "./pages/public/iFrames";
import { ConnectedAccountsSettingsPage, EmailSettingsPage, GeneralInformationSettingsPage, IframePage, LocalizationSettingsPage, PayoutInformationSettings } from "./pages/private/Settings";
import { EditHoursPage, GroupingsPage, TicketsPage } from "./pages/private/Hours-And-Tickets";
import BussinessHoursPage from "pages/private/Calendars/Business-Hours";
import RegistrationPage from "pages/public/Registration/registration";
import { PricesPage } from "pages/private/Hours-And-Tickets/Prices";
import { TimingsPage } from "pages/private/Hours-And-Tickets/Timings";
import { WorkflowSettingsPage } from "pages/private/Settings/Workflows";
function App() {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);
  const parkData = useSelector((state: any) => state.firestore.ordered.park);
  useEffect(() => {
    if (!uid) return;
    store.firestore.onSnapshot({
      collection: "Reservations",
      where: ["ParkId", "==", uid],
      storeAs: "reservations"
    });
  }, [uid]);
  useEffect(() => {
    if (!uid) return;
    store.firestore.onSnapshot({
      collection: "Bookings",
      where: ["ParkId", "==", uid],
      storeAs: "bookings"
    });
  }, [uid]);
  useEffect(() => {
    if (!uid) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      storeAs: "park"
    });
  }, [uid]);
  useEffect(() => {
    if (!uid) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      subcollections: [{
        collection: "Seasons"
      }],
      storeAs: "seasons"
    });
  }, [uid]);
  useEffect(() => {
    if (!uid || !parkData) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      subcollections: [{
        collection: "Seasons",
        doc: parkData[0]?.ActiveSeason
      }],
      storeAs: "activeSeason"
    });
  }, [uid, parkData]);
  useEffect(() => {
    if (!uid || !activeSeasonRedux || activeSeasonRedux?.length < 0 || !activeSeasonRedux[0]?.id) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      subcollections: [{
        collection: "Seasons",
        doc: activeSeasonRedux[0]?.id
      }, {
        collection: "Tickets"
      }],
      storeAs: "activeSeasonTickets"
    });
  }, [uid, activeSeasonRedux]);
  useEffect(() => {
    if (!uid || !activeSeasonRedux || activeSeasonRedux?.length < 0 || !activeSeasonRedux[0]?.id) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      subcollections: [{
        collection: "Seasons",
        doc: activeSeasonRedux[0]?.id
      }, {
        collection: "Ticket-Groups"
      }],
      storeAs: "activeSeasonTicketsGroups"
    });
  }, [uid, activeSeasonRedux]);
  useEffect(() => {
    if (!uid) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      subcollections: [{
        collection: "Business-Hours"
      }],
      storeAs: "activeSeasonBusinessHours"
    });
  }, [uid]);
  useEffect(() => {
    if (!uid || !activeSeasonRedux || activeSeasonRedux?.length < 0 || !activeSeasonRedux[0]?.id) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      subcollections: [{
        collection: "Seasons",
        doc: activeSeasonRedux[0]?.id
      }, {
        collection: "Prices"
      }],
      storeAs: "activeSeasonPrices"
    });
  }, [uid, activeSeasonRedux]);
  useEffect(() => {
    if (!uid || !activeSeasonRedux || activeSeasonRedux?.length < 0 || !activeSeasonRedux[0]?.id) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      subcollections: [{
        collection: "Seasons",
        doc: activeSeasonRedux[0]?.id
      }, {
        collection: "Timings"
      }],
      storeAs: "activeSeasonTimings"
    });
  }, [uid, activeSeasonRedux]);
  useEffect(() => {
    if (!uid || !activeSeasonRedux || activeSeasonRedux?.length < 0 || !activeSeasonRedux[0]?.id) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      subcollections: [{
        collection: "Seasons",
        doc: activeSeasonRedux[0]?.id
      }, {
        collection: "Timing-Groups"
      }],
      storeAs: "activeSeasonTimingGroups"
    });
  }, [uid, activeSeasonRedux]);
  useEffect(() => {
    if (!uid) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      subcollections: [{
        collection: "Connected_Accounts",
        doc: "accounts"
      }],
      storeAs: "connectedAccounts"
    });
  }, [uid]);
  useEffect(() => {
    if (!uid) return;
    store.firestore.onSnapshot({
      collection: "Templates",
      where: ["uid", "==", uid],
      storeAs: "emailTemplates"
    });
  }, [uid]);
  useEffect(() => {
    if (!uid) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      subcollections: [{
        collection: "stripe_connect",
        doc: "account"
      }],
      storeAs: "stripeConnect"
    });
  }, [uid]);
  useEffect(() => {
    if (!uid) return;
    store.firestore.onSnapshot({
      collection: "Parks",
      doc: uid,
      subcollections: [{
        collection: "stripe_connect",
        doc: "payout_info"
      }],
      storeAs: "payout_info"
    });
  }, [uid]);
  return <BrowserRouter>
			<Routes>
				<Route path="/" element={<DashboardPage />} />
				<Route path="/login" element={<LoginPageBlock />} />
				<Route path="/forgot-password" element={<PasswordResetPage />} />
				<Route path="/registration" element={<RegistrationPage />} />

				<Route path="calendars">
					<Route path="events-calendar" element={<EventCalendarPage />} />
					<Route path="businesshours" element={<BussinessHoursPage />} />
					<Route path="edit-days" element={<EditHoursPage />} />
				</Route>

				<Route path="settings">
					<Route path="media-library" element={<MediaLibraryPage />} />
					<Route path="basic-information" element={<GeneralInformationSettingsPage />} />
					<Route path="workflows" element={<WorkflowSettingsPage />} />
					<Route path="localization" element={<LocalizationSettingsPage />} />
					<Route path="payout-information" element={<PayoutInformationSettings />} />
					<Route path="accounts" element={<ConnectedAccountsSettingsPage />} />
					<Route path="email-templates" element={<EmailSettingsPage />} />
					<Route path="iFrames" element={<IframePage />} />
				</Route>

				<Route path="hoursandtickets">
					<Route path="prices" element={<PricesPage />} />
					<Route path="tickets" element={<TicketsPage />} />
					<Route path="timings" element={<TimingsPage />} />
					<Route path="groupings" element={<GroupingsPage />} />
				</Route>

				<Route path="iFrames">
					<Route path="calendar/:parkid" element={<IFrameCalendarPage />} />
					<Route path="today-open/:parkid" element={<TodayOpenPage />} />
					<Route path="currently-open/:parkid" element={<CurrentlyOpenPage />} />
				</Route>
			</Routes>
		</BrowserRouter>;
}
export default App;