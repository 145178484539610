import { Button, Grid, Paper, Typography } from "@mui/material";
import LoginForm from "./Login-Form";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
export function LoginPaperComponent() {
  const navigate = useNavigate();
  const {
    t
  } = useTranslation("common");
  return <Paper elevation={3} style={{
    maxWidth: "500px",
    textAlign: "center",
    margin: "auto",
    padding: "1rem",
    borderRadius: "1rem",
    opacity: "1",
    backgroundColor: "white",
    backdropFilter: "blur(150px)",
    WebkitBackdropFilter: "blur(15p0x)"
  }}>
			<Typography typography={"h1"}>{t("text.login")}</Typography>
			<LoginForm />
			<Grid container spacing={1} style={{
      marginTop: "1rem"
    }}>
				<Grid item xs={12} sm={6}>
					<Button variant="text" fullWidth onClick={() => {
          navigate("/forgot-password");
        }}>
						{t("button.forgotPassword")}
					</Button>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Button variant="text" fullWidth onClick={() => {
          navigate("/registration");
        }}>
						{t("button.registration")}
					</Button>
				</Grid>
			</Grid>
		</Paper>;
}