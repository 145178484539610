import { Grid } from "@mui/material";
import { PasswordReset } from "components/base/Password-Reset";
import { PublicPageLayout } from "components/common/layouts/Page/public";
export function PasswordResetPage() {
  return <PublicPageLayout link={"https://images.pexels.com/photos/3225517/pexels-photo-3225517.jpeg?auto=compress&cs=tinysrgb"}>
        <Grid container spacing={1}>
            <Grid item xs={12} style={{
        marginTop: "3rem"
      }}>
                <PasswordReset />
            </Grid>
        </Grid>
    </PublicPageLayout>;
}