import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import MediaLibraryDialog from "components/base/Dialogs/Media-Library-Dialog";
import { db } from "utilities/Firebase/firebase-redux";
export default function ParkShownImages() {
  const park = useSelector((state: any) => state.firestore.ordered.park);
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const [parkImages, setParkImages] = useState<string[]>([]);
  const [isParkModalOpen, setParkModalIsOpen] = useState(false);
  useEffect(() => {
    if (!park) return;
    setParkImages(park[0]?.ParkImages || []);
  }, [park]);
  function imageUploaded(link: string) {
    const newArr: string[] = JSON.parse(JSON.stringify(parkImages));
    const index = newArr.indexOf(link);
    if (newArr?.length >= 9) {
      toast.error("You can only select up to 10 images");
      return;
    }
    if (index >= 0) {
      toast.error("This Image is already selected");
      return;
    }
    newArr.push(link);
    setParkImages(newArr);
    const newData = {
      ParkImages: newArr
    };
    const myDoc = doc(db, `/Parks/${uid}`);
    setDoc(myDoc, newData, {
      merge: true
    }).then(() => {
      toast.success("Updated Image");
      closeMediaLibraryModal();
    }).catch(err => {
      toast.error("There was an error while Updating your Image");
    });
  }
  function deleteImageFunction(link: string) {
    const newArr: string[] = JSON.parse(JSON.stringify(parkImages));
    const index = newArr.indexOf(link);
    newArr.splice(index, 1);
    setParkImages(newArr);
    const newData = {
      ParkImages: newArr
    };
    const myDoc = doc(db, `/Parks/${uid}`);
    setDoc(myDoc, newData, {
      merge: true
    }).then(() => {
      toast.success("Updated Image");
      closeMediaLibraryModal();
    }).catch(err => {
      toast.error("There was an error while Updating your Image");
    });
  }
  function openMediaLibraryModal() {
    setParkModalIsOpen(true);
  }
  function closeMediaLibraryModal() {
    setParkModalIsOpen(false);
  }
  return <AccordionCustomComponent title={"Public Images"}>
			<MediaLibraryDialog isOpen={isParkModalOpen} closeFunction={closeMediaLibraryModal} imageOnClickFunction={imageUploaded} />
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<div style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center"
        }}>
						{parkImages?.map(link => {
            return <div style={{
              border: "1px solid black",
              padding: "0.5rem",
              width: "100px",
              height: "100px",
              margin: "0 0.5rem"
            }}>
									<img src={link} alt="" style={{
                maxWidth: "100%",
                maxHeight: "60px"
              }} />
									<Button color="error" onClick={() => {
                deleteImageFunction(link);
              }}>
										Delete
									</Button>
								</div>;
          })}
					</div>
				</Grid>
				<Grid item xs={12}>
					<Button component="span" fullWidth variant="contained" onClick={openMediaLibraryModal}>
						Select Images
					</Button>
				</Grid>
			</Grid>
		</AccordionCustomComponent>;
}