import { MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
export function BusinessHourSelect({
  setBusinessHour
}: {
  setBusinessHour: any;
}) {
  const activeSeasonTimingGroupsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTimingGroups);
  const [select, setSelect] = useState<string>("none");
  function onChange(e: any) {
    setSelect(e?.target?.value);
    setBusinessHour(e?.target?.value);
  }
  return <Select fullWidth value={select} onChange={onChange}>
			<MenuItem key={"Business Hour: None"} value={"none"}>
				Leer
			</MenuItem>
			<MenuItem key={"Business Hour: Delete"} value={"delete"}>
				Entfernen
			</MenuItem>

			{activeSeasonTimingGroupsRedux?.map((businessHour: any, index: number) => {
      return <MenuItem style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        alignContent: "center"
      }} value={businessHour?.id} key={`Business Hour: ${businessHour?.id}`}>
							<span style={{
          color: businessHour?.Color
        }}>
								⬤
							</span>{" "}
							{businessHour?.Title}
						</MenuItem>;
    })}
		</Select>;
}