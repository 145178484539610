import { Button, Grid, TextField } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import { db } from "utilities/Firebase/firebase-redux";
export default function SocialMediaList() {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const park = useSelector((state: any) => state.firestore.ordered.park);
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedIn] = useState("");
  const [youtube, setYouTube] = useState("");
  useEffect(() => {
    if (!park) return;
    setFacebook(park[0]?.Facebook || "");
    setInstagram(park[0]?.Instagram || "");
    setLinkedIn(park[0]?.LinkedIn || "");
    setYouTube(park[0]?.YouTube || "");
  }, [park]);
  function submitHandler(event: any) {
    event.preventDefault();
    if (!uid) {
      toast.error("Not logged in");
      return;
    }
    const myDoc = doc(db, `/Parks/${uid}`);
    const newData = {
      Facebook: facebook,
      Instagram: instagram,
      LinkedIn: linkedin,
      YouTube: youtube
    };
    setDoc(myDoc, newData, {
      merge: true
    }).then(() => {
      toast.success("Updated Social Media Links");
    }).catch(err => {
      toast.error("There was an error while Updating your Social Media Links");
    });
  }
  return <AccordionCustomComponent title={"Social Media"}>
			<form onSubmit={submitHandler}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextField fullWidth label="Facebook" size="small" value={facebook} onChange={e => {
            setFacebook(e?.target?.value);
          }} />
					</Grid>
					<Grid item xs={12}>
						<TextField fullWidth label="Instagram" size="small" value={instagram} onChange={e => {
            setInstagram(e?.target?.value);
          }} />
					</Grid>
					<Grid item xs={12}>
						<TextField fullWidth label="LinkedIn" size="small" value={linkedin} onChange={e => {
            setLinkedIn(e?.target?.value);
          }} />
					</Grid>
					<Grid item xs={12}>
						<TextField fullWidth label="YouTube" size="small" value={youtube} onChange={e => {
            setYouTube(e?.target?.value);
          }} />
					</Grid>
					<Grid item xs={12}>
						<Button variant="contained" fullWidth type="submit">
							Update
						</Button>
					</Grid>
				</Grid>
			</form>
		</AccordionCustomComponent>;
}