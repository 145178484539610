import { Button, Grid, TextField, TextareaAutosize, Typography } from "@mui/material";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import BaseDialogComponent from "components/common/layouts/Dialog";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { db } from "utilities/Firebase/firebase-redux";
interface FAQType {
  title: string;
  description: string;
}
export default function FAQSettingsAccordion() {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const park = useSelector((state: any) => state.firestore.ordered.park);
  const [isDialogVisible, setDialogIsVisible] = useState(false);
  const [faqs, setFaqs] = useState<FAQType[]>([]);
  const [faqIndex, setFaqIndex] = useState<number>(-1);
  useEffect(() => {
    if (!park) return;
    setFaqs(park[0]?.FAQ || []);
  }, [park]);
  function addNewFAQItem() {
    const newFaqs = structuredClone(faqs);
    newFaqs.push({
      title: "",
      description: ""
    });
    setFaqs(newFaqs);
    openDialog(newFaqs.length - 1);
  }
  function openDialog(index: number) {
    setFaqIndex(index);
    setDialogIsVisible(true);
  }
  function closeDialog() {
    setDialogIsVisible(false);
  }
  function updateFAQ(title: string, description: string) {
    const newFaqs = structuredClone(faqs);
    newFaqs[faqIndex] = {
      title: title,
      description: description
    };
    const docRef = doc(db, `/Parks/${uid}`);
    updateDoc(docRef, {
      FAQ: newFaqs
    }).then(res => {
      toast.success("Successfully updated FAQ");
      setFaqs(newFaqs);
    }).catch(err => {
      toast.error("Error while updating FAQ");
    });
    closeDialog();
  }
  function deleteFAQ(index: number) {
    const newFaqs = structuredClone(faqs);
    newFaqs.splice(index, 1);
    const docRef = doc(db, `/Parks/${uid}`);
    updateDoc(docRef, {
      FAQ: newFaqs
    }).then(res => {
      toast.success("Successfully deleted FAQ");
      setFaqs(newFaqs);
    }).catch(err => {
      toast.error("Error while deleting FAQ");
    });
    closeDialog();
  }
  return <AccordionCustomComponent title={"FAQ"}>
			<FAQDialogComponent isOpen={isDialogVisible} closeFunction={closeDialog} deleteFunction={() => deleteFAQ(faqIndex)} setFAQ={updateFAQ} faq={faqs[faqIndex]} key={`FAQ Dialog ${faqIndex}`} />
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Button variant="outlined" fullWidth onClick={addNewFAQItem}>
						+
					</Button>
				</Grid>
				<Grid item xs={12}>
					{faqs?.map((faq, index) => {
          return <FAQItem title={faq?.title} description={faq?.description} editFunction={() => openDialog(index)} />;
        })}
				</Grid>
			</Grid>
		</AccordionCustomComponent>;
}
function FAQDialogComponent({
  isOpen,
  closeFunction,
  setFAQ,
  faq,
  deleteFunction
}: {
  isOpen: boolean;
  closeFunction: any;
  setFAQ: any;
  faq?: FAQType;
  deleteFunction: any;
}) {
  const [descriptionText, setDescriptionText] = useState(faq?.description || "");
  const [title, setTitle] = useState(faq?.title || "");
  function updateFunction() {
    setFAQ(title, descriptionText);
  }
  return <BaseDialogComponent isOpen={isOpen} title={"Edit FAQ"} closeFunction={closeFunction} deletFunction={deleteFunction} updateFunction={updateFunction}>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextField fullWidth value={title} onChange={e => {
            setTitle(e?.target?.value);
          }} placeholder="Frage" />
					</Grid>
					<Grid item xs={12}>
						<TextareaAutosize required value={descriptionText} onChange={e => {
            setDescriptionText(e?.target?.value);
          }} minRows={5} style={{
            width: "100%"
          }} placeholder="Antwort" />
					</Grid>
					<Grid item xs={12}></Grid>
				</Grid>
			</Grid>
		</BaseDialogComponent>;
}
function FAQItem({
  title,
  description,
  editFunction
}: {
  title: string;
  description: string;
  editFunction: any;
}) {
  return <AccordionCustomComponent title={title || "Leere Frage"}>
			<Typography typography={"p"} style={{
      textAlign: "start"
    }}>
				{description || "Leere Antwort"}
			</Typography>
			<Button onClick={editFunction}>Edit</Button>
		</AccordionCustomComponent>;
}