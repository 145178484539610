import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import listWeekPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import { DialogActions } from "@mui/material";
import { useSelector } from "react-redux";
export default function SelectNewTicketModal({
  visible,
  saveFunction,
  abortFunction,
  date
}: {
  visible: boolean;
  saveFunction: any;
  abortFunction: any;
  date: Date;
}) {
  const calendarEl: any = useRef(null);
  const [events, setEvents] = useState<any[]>([]);
  const [businessHours, setBusinessHours] = useState([]);
  const groupings = useSelector((state: any) => state.firestore.ordered.groupings);
  const ticketsRedux = useSelector((state: any) => state.firestore.ordered.tickets);
  const businessHoursParksRedux = useSelector((state: any) => state.firestore.ordered.businessHours);
  useEffect(() => {
    if (!businessHoursParksRedux) return;
    setBusinessHours(businessHoursParksRedux[0]?.BusinessHours);
  }, [businessHoursParksRedux]);
  function eventClick(e: any) {
    saveFunction(e?.event);
  }
  function dateClick(e: any) {
    if (calendarEl?.current?.getApi()?.view?.type === "dayGridMonth") {
      calendarEl?.current?.getApi().changeView("dayGridWeek", e?.date);
    } else if (calendarEl?.current?.getApi()?.view?.type === "dayGridWeek") {
      calendarEl?.current?.getApi().changeView("timeGridDay", e?.date);
    } else if (calendarEl?.current?.getApi()?.view?.type === "timeGridDay") {
      calendarEl?.current?.getApi().changeView("listWeek", e?.date);
    }
  }
  function findMyGrouping(id: string) {
    let returnVal: any = {};
    groupings?.forEach((grouping: any) => {
      if (returnVal?.Title) return;
      if (grouping?.id === id) returnVal = grouping;
    });
    return returnVal;
  }
  function findMyTicket(id: string) {
    let returnVal: any = {};
    ticketsRedux?.forEach((ticket: any) => {
      if (returnVal?.Title) return;
      if (ticket?.id === id) returnVal = ticket;
    });
    return returnVal;
  }
  function findAmountOfTimesInMinutesBetweenStartAndEndTime(startTime: Date, endTime: Date, minutesInMillisecs: number) {
    const startTimeMillisecs = startTime.getTime();
    const endTimeMillisecs = endTime.getTime();
    const deltaTime = endTimeMillisecs - startTimeMillisecs;
    return deltaTime / minutesInMillisecs;
  }
  useEffect(() => {
    if (!businessHours) return;
    const myEvents: any[] = [];
    businessHours?.forEach((bHour: any) => {
      const startTime = new Date(bHour?.StartTime);
      const endTime = new Date(bHour?.EndTime);
      const bHourDate = new Date(bHour?.Date);
      if (bHourDate.getTime() !== date.getTime()) return;
      bHour?.Groupings?.forEach((grouping: any) => {
        const myGrouping = findMyGrouping(grouping);
        myGrouping?.Tickets?.forEach((ticket: any) => {
          const myTicket = findMyTicket(ticket?.TicketId);
          const minutesInMillisecs = myTicket?.Synchron * 60 * 1000;
          const amountBetweenStartAndEnd = findAmountOfTimesInMinutesBetweenStartAndEndTime(startTime, endTime, minutesInMillisecs);
          const startDateOfDay = new Date(bHour?.Date);
          startDateOfDay.setHours(startTime?.getHours());
          startDateOfDay.setMinutes(startTime?.getMinutes());
          const endDateOfThisDay: Date = new Date(bHour?.Date);
          endDateOfThisDay.setHours(endTime.getHours());
          endDateOfThisDay.setMinutes(endTime.getMinutes());
          endDateOfThisDay.setSeconds(0);
          for (let i = 0; i <= amountBetweenStartAndEnd; i++) {
            const startTimeOffsetTime = new Date(myTicket?.TicketStartTime || 0);
            const startTimeOffsetMilliseconds = startTimeOffsetTime.getHours() * 60 * 60 * 1000 + startTimeOffsetTime.getMinutes() * 60 * 1000;
            const startDateWithOffset: Date = new Date(startDateOfDay.getTime() + startTimeOffsetMilliseconds);
            const startDate: Date = new Date(startDateWithOffset.getTime() + minutesInMillisecs * i);
            const endTimeOffsetTime = new Date(myTicket?.TicketEndTime || 0);
            const endTimeOffsetMilliseconds = endTimeOffsetTime.getHours() * 60 * 60 * 1000 + endTimeOffsetTime.getMinutes() * 60 * 1000;
            const endDateWithOffset: Date = new Date(endDateOfThisDay.getTime() - endTimeOffsetMilliseconds);
            if (startDate.getTime() > endDateWithOffset.getTime()) return;
            const duration: Date = new Date(myTicket?.Duration);
            const durationHours = duration?.getHours() * 60 * 60 * 1000;
            const endDateByDuration: Date = new Date(startDate?.getTime() + durationHours);
            const endDate = myTicket?.IsBusinessHoursEndOfDayUsed ? endDateOfThisDay : endDateByDuration;
            const newEvent = {
              title: myTicket?.Title,
              start: startDate,
              end: endDate.getTime() >= endDateOfThisDay.getTime() ? endDateOfThisDay : endDateByDuration,
              color: myTicket?.Color,
              id: myTicket?.id
            };
            myEvents.push(newEvent);
          }
        });
      });
    });
    setEvents(myEvents);
  }, [businessHours, ticketsRedux, groupings, date]);

  // zeige nur die tickets an dem Tag an
  return <Dialog open={visible}>
			<DialogTitle>Update selected Ticket</DialogTitle>
			<DialogContent>
				<FullCalendar nowIndicator now={date} locale={deLocale} locales={[deLocale]} ref={calendarEl} firstDay={1} dayMaxEvents={10} headerToolbar={{
        center: "title",
        start: "",
        end: "timeGridDay,listWeek"
      }} events={events} plugins={[interactionPlugin, timeGridPlugin, listWeekPlugin]} initialView="listWeek" eventClick={eventClick} dateClick={dateClick} height={"60vh"} />
				{/* <EventsCalendar groupings={groupings} tickets={ticketsRedux} businessHours={businessHours} /> */}
			</DialogContent>
			<DialogActions>
				<Button onClick={abortFunction}>Abort</Button>
			</DialogActions>
		</Dialog>;
}