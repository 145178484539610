import { ParkNavigation } from "components/base/Drawer";
export function PrivatePageLayout({
  link,
  activeLink = "",
  activeSubLink = "",
  children,
  title
}: {
  link: string;
  activeLink?: string;
  activeSubLink?: string;
  children?: any;
  title: string;
}) {
  return <div style={{
    width: "100%",
    height: "100%",
    minWidth: "100vw",
    minHeight: "100vh",
    backgroundImage: `url('${link}')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center"
  }}>
			<ParkNavigation activeLink={activeLink} activeSubLink={activeSubLink} title={title} />
			{children}
		</div>;
}