export function PublicPageLayout({
  link,
  children
}: {
  link: string;
  children?: any;
}) {
  return <div style={{
    width: "100%",
    height: "100%",
    minWidth: "100vw",
    minHeight: "100vh",
    backgroundImage: `url('${link}')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center center'
  }}>
        {children}
    </div>;
}