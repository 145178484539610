import { Grid } from "@mui/material";
import { UmsatzOnDate } from "components/common/analytics/stats/umsatz-on-date";
import { VisitorsOnDate } from "components/common/analytics/stats/visitors-on-date";
import { PrivatePageLayout } from "components/common/layouts/Page/private";
import { EventCalendarDashboard } from "features/Dashboard/Events-Calendar";
import { StatsCard } from "features/Dashboard/StatsCard";
import { useTranslation } from "react-i18next";
export function DashboardPage() {
  const {
    t
  } = useTranslation("common");
  return <PrivatePageLayout link={"https://images.pexels.com/photos/1528361/pexels-photo-1528361.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} activeLink={"Dashboard"} title={t("title.dashboard")}>
			<div style={{
      maxWidth: '1200px',
      margin: 'auto',
      padding: '0.5rem'
    }}>
				<Grid container spacing={1}>
					{/* <Grid item xs={12}>
      <StatsCard
      	title={"Heute"}
      >
      	<VisitorsOnDate
      		title={"Besucher"}
      		visitors={11}
      	/>
      	<UmsatzOnDate
      		title={"Umsatz"}
      		umsatz={"1234,00"}
      	/>
      </StatsCard>
      </Grid> */}
					{/* <Grid item xs={12}>
      <StatsCard
      	title={"Morgen"}
      >
      	<VisitorsOnDate
      		title={"Besucher"}
      		visitors={25}
      	/>
      	<UmsatzOnDate
      		title={"Umsatz"}
      		umsatz={"321,00"}
      	/>
      </StatsCard>
      </Grid> */}
					<Grid item xs={12}>
						<EventCalendarDashboard />
					</Grid>
				</Grid>
			</div>
		</PrivatePageLayout>;
}