import { Grid, TextField } from "@mui/material";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { db } from "utilities/Firebase/firebase-redux";
import AreYouSureComponent from "../Warnings/are-you-sure";
import { useFormik } from "formik";
import { FormDialogLayout } from "components/common/layouts/Dialog/form-dialog-layout";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from "wsywyg-html-editor/build/ckeditor";
export default function EdiaEmailTemplateModal({
  isOpen,
  id,
  closeEditEmailTemplateModalFunction
}: {
  isOpen: boolean;
  id: string;
  closeEditEmailTemplateModalFunction: any;
}) {
  const [isAreYouSureVisible, setAreYouSureIsVisible] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const emailTemplates = useSelector((state: any) => state.firestore.ordered.emailTemplates);
  const formik = useFormik<any>({
    initialValues: {
      title: "",
      subject: "",
      html: "",
      to: "",
      cc: "",
      bcc: "",
      replyTo: ""
    },
    onSubmit: updateTemplate
  });

  // function findAndSetTemplate(templates: any[], id: string) {
  // 	templates?.forEach((template: any) => {
  // 		if (template?.id !== id) return;
  // 		formik.setFieldValue("title", template?.title || "");
  // 		formik.setFieldValue("subject", template?.subject || "");
  // 		formik.setFieldValue("html", template?.html || "");
  // 		formik.setFieldValue("replyTo", template?.replyTo || "");
  // 		formik.setFieldValue("cc", template?.cc || "");
  // 		formik.setFieldValue("bcc", template?.bcc || "");
  // 		formik.setFieldValue("to", template?.to || "");
  // 	});
  // }

  useEffect(() => {
    if (!emailTemplates) return;
    emailTemplates?.forEach((template: any) => {
      if (template?.id !== id) return;
      formik.setFieldValue("title", template?.title || "");
      formik.setFieldValue("subject", template?.subject || "");
      formik.setFieldValue("html", template?.html || "");
      formik.setFieldValue("replyTo", template?.replyTo || "");
      formik.setFieldValue("cc", template?.cc || "");
      formik.setFieldValue("bcc", template?.bcc || "");
      formik.setFieldValue("to", template?.to || "");
    });
    // findAndSetTemplate(emailTemplates, id);
  }, [id, emailTemplates, isReady]);
  function updateTemplate(data: any) {
    const myDoc = doc(db, `/Templates/${id}`);
    updateDoc(myDoc, {
      title: data.title,
      subject: data.subject,
      html: data.html,
      replyTo: data.replyTo,
      cc: data.cc,
      bcc: data.bcc,
      to: data.to,
      updatedLastOn: Date.now()
    }).then(res => {
      toast.success("Updated Template");
      closeEditEmailTemplateModalFunction();
      console.log({
        res
      });
    }).catch(err => {
      toast.error("Error while updating the Template");
      console.log({
        err
      });
    });
  }
  function deleteTemplate() {
    const myDoc = doc(db, `/Templates/${id}`);
    deleteDoc(myDoc).then(res => {
      toast.success("Deleted Template");
      closeEditEmailTemplateModalFunction();
      console.log({
        res
      });
    }).catch(err => {
      toast.error("Error while deleting the Template");
      console.log({
        err
      });
    });
  }
  return <FormDialogLayout isOpen={isOpen} title={"Edit Email Template"} closeFunction={closeEditEmailTemplateModalFunction} submitFunction={formik.handleSubmit}>
			<AreYouSureComponent isOpen={isAreYouSureVisible} title={"Are you sure you want to delete this Event?"} noFunction={() => {
      setAreYouSureIsVisible(false);
    }} yesFunction={deleteTemplate} />
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<TextField fullWidth label="Title" placeholder="Email Template Title for a Booking" onChange={formik.handleChange} name="title" value={formik.values.title} />
				</Grid>
				<Grid item xs={12}>
					<TextField fullWidth label="Recipient" placeholder="empfanger@beispiel.de" onChange={formik.handleChange} name="to" value={formik.values.to} />
				</Grid>
				<Grid item xs={12}>
					<TextField fullWidth label="CC" placeholder="weitererempfanger@beispiel.de, nocheinweiterer@beispiel.de" onChange={formik.handleChange} name="cc" value={formik.values.cc} />
				</Grid>
				<Grid item xs={12}>
					<TextField fullWidth label="BCC" placeholder="weitererempfanger@beispiel.de, nocheinweiterer@beispiel.de" onChange={formik.handleChange} name="bcc" value={formik.values.bcc} />
				</Grid>
				<Grid item xs={12}>
					<TextField fullWidth label="replyTo" placeholder="replyToEmail@yourcompany.de" onChange={formik.handleChange} name="replyTo" value={formik.values.replyTo} />
				</Grid>
				<Grid item xs={12}>
					<TextField fullWidth label="Subject" placeholder="Email Subject for a Booking" onChange={formik.handleChange} name="subject" value={formik.values.subject} />
				</Grid>
				<Grid item xs={12}>
					<CKEditor editor={Editor.Editor} data={formik.values.html} onReady={editor => {
          // console.log('CKEditor React Component is ready to use!', editor);
          // findAndSetTemplate(emailTemplates, id);
          setIsReady(true);
        }} onChange={(event, editor) => {
          const data = editor.getData();
          formik.setFieldValue("html", data);
          // console.log({ event, editor, data });
        }} config={{
          htmlSupport: {
            allow: [{
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }]
          },
          removePlugins: ["Title"],
          placeholder: "Start Typing here"
        }} />
				</Grid>
			</Grid>
		</FormDialogLayout>;
}