import { Box, MenuItem, Typography } from "@mui/material";
import { GroupingTemplate } from "components/common/layouts/Settings/Grouping-Template/grouping-template";
import { SettingsListItem } from "components/common/layouts/Settings/Settings-List-Item";
import WorkflowDialog from "./Dialog";
import { useState } from "react";
export default function Workflows() {
  const [isWorkflowDialogVisible, setWorkflowDialogIsVisible] = useState(false);
  return <Box>
        <WorkflowDialog open={isWorkflowDialogVisible} closeFunction={() => {
      setWorkflowDialogIsVisible(false);
    }} deleteFunction={undefined} updateFunction={undefined} />
        <GroupingTemplate isAddButtonVisible title={"Workflows"} addNewFunction={() => {}}>
            <SettingsListItem title={"asd"}>
                <MenuItem onClick={() => {
          setWorkflowDialogIsVisible(true);
        }}>
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {}}>
                    Delete
                </MenuItem>
            </SettingsListItem>
        </GroupingTemplate>
    </Box>;
}