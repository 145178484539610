import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
export default function DrawerListItemDropdown({
  Icon,
  isListItemSelected,
  children,
  title
}: {
  Icon: any;
  isListItemSelected: boolean;
  children?: any;
  title: string;
}) {
  const [isCollapseVisible, setCollapeIsVisible] = useState(isListItemSelected || false);
  return <>
			<ListItemButton onClick={() => {
      setCollapeIsVisible(!isCollapseVisible);
    }}>
				<ListItemIcon sx={{
        minWidth: 0,
        mr: 3,
        justifyContent: "center"
      }}>
					<Icon style={{
          color: isListItemSelected ? "green" : "black"
        }} />
				</ListItemIcon>
				<ListItemText primary={title} style={{
        color: isListItemSelected ? "green" : "black"
      }} />
				{isCollapseVisible ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>

			<Collapse in={isCollapseVisible} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{children}
				</List>
			</Collapse>
		</>;
}