import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listWeekPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import { useEffect, useRef, useState } from "react";
import { Checkbox, Paper, Stack, Switch, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import { toast } from "react-toastify";
import { db } from "utilities/Firebase/firebase-redux";
import { deleteDoc, doc } from "firebase/firestore";
import AreYouSureComponent from "components/base/Dialogs/Warnings/are-you-sure";
import EditEventModal from "components/base/Dialogs/Edit-Dialogs/edit-event-modal";
import WhyDidReservierungChangeModal from "components/base/Dialogs/old/why-did-reservierung-change";
import { ExportEventsOnDateButton } from "./export-button";
export function EventCalendar({
  reservationId,
  isBooking = false,
  bookingId
}: {
  reservationId: string | undefined;
  isBooking: boolean;
  bookingId: string | undefined;
}) {
  const bookingsRedux = useSelector((state: any) => state.firestore.ordered.bookings);
  const reservationsRedux = useSelector((state: any) => state.firestore.ordered.reservations);
  const ticketsRedux = useSelector((state: any) => state.firestore.ordered.tickets);
  const calendarEl: any = useRef(null);
  const [selectedEvent, setSelectedEvent] = useState<any>({});
  const [eventChangeKey, setEventChangeKey] = useState(0);
  const [addResKey, setAddResKey] = useState(0);
  const [whyDidReservierungChangeKey, setWhyDidReservierungChangeKey] = useState(0);
  const [isAddReservierungVisible, setIsAddReservierungVisible] = useState(false);
  const [isEditReservierungVisible, setIsEditReservierungVisible] = useState(false);
  const [isWhyDidEventChangeVisible, setIsWhyDidEventChangeVisible] = useState(false);
  const [isShowEachTicket, setIsShowEachTicket] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [eventTickets, setEventTickets] = useState<any[]>([]);
  // const [eventTicketsUnworked, setEventTicketsUnworked] = useState<any[]>([])
  const [calendarVisibleTickets, setCalendarVisibleTickets] = useState<any[]>([]);
  const [isShowReservationsActive, setShowReservationsIsActive] = useState<boolean>(true);
  const [isShowBookingsActive, setShowBookingsIsActive] = useState<boolean>(true);
  const [selectedId, setSelectedId] = useState<string>(reservationId || bookingId || "");
  const [isEventBooking, setEventIsBooking] = useState<boolean>(false);
  const [isAreYouSureVisible, setAreYouSureIsVisible] = useState(false);
  useEffect(() => {
    if (!reservationId && !bookingId) return;
    setSelectedId(reservationId || bookingId || "");
    setIsEditReservierungVisible(true);
    setEventIsBooking(bookingId ? true : false);
  }, [reservationId, bookingId, isBooking]);
  useEffect(() => {
    const eventsArray: any[] = [];
    if (!bookingsRedux) return;
    if (isShowBookingsActive) {
      bookingsRedux?.forEach((booking: any) => {
        eventsArray.push(booking);
      });
    }
    if (!reservationsRedux) return;
    if (isShowReservationsActive) {
      reservationsRedux?.forEach((reservation: any) => {
        eventsArray.push(reservation);
      });
    }
    setSelectedEvents(eventsArray);
    // console.log({bookingsRedux, reservationsRedux, eventsArray})
  }, [bookingsRedux, reservationsRedux, isShowReservationsActive, isShowBookingsActive]);
  useEffect(() => {
    if (!events || !eventTickets) return;
    if (isShowEachTicket) {
      setCalendarVisibleTickets(eventTickets);
    } else {
      setCalendarVisibleTickets(events);
    }
  }, [isShowEachTicket, events, eventTickets]);
  function getEarliestTicketStartDate(tickets: any[]) {
    var returnDate: Date = new Date(0);
    tickets?.forEach(ticket => {
      const ticketTime: Date = new Date(ticket?.StartDate?.seconds * 1000);
      if (returnDate.getTime() >= ticketTime.getTime()) return;
      returnDate = ticketTime;
    });
    return returnDate;
  }
  function getLatestTicketEndDate(tickets: any[]) {
    var returnDate: Date = new Date(0);
    tickets?.forEach(ticket => {
      const ticketTime: Date = new Date(ticket?.EndDate?.seconds * 1000);
      if (returnDate.getTime() >= ticketTime.getTime()) return;
      returnDate = ticketTime;
    });
    return returnDate;
  }
  useEffect(() => {
    const eventDataNew: any[] = [];
    const eventDataByTicketNew: any[] = [];
    if (!selectedEvents || !ticketsRedux) return;
    selectedEvents.forEach((event: any) => {
      // event Data
      const myTicket = getTicket(event?.Tickets[0]?.TicketId);
      const startTime = getEarliestTicketStartDate(event?.Tickets);
      const endTime = getLatestTicketEndDate(event?.Tickets);
      eventDataNew.push({
        title: `${event?.PersonalInformation?.FirstName} ${event?.PersonalInformation?.LastName}`,
        start: startTime,
        end: endTime,
        color: myTicket?.color,
        // random colors for each event, with outline of ticket color
        editable: true,
        startEditable: true,
        durationEditable: true,
        extendedProps: {
          id: event?.id,
          isEventBooking: event?.PaymentMethod === "cash" ? false : true,
          vititors: event?.VisitorAmount,
          first_name: event?.PersonalInformation?.FirstName,
          last_name: event?.PersonalInformation?.LastName,
          email: event?.PersonalInformation?.Email,
          tel: event?.PersonalInformation?.PhoneNumber,
          postal_code: event?.PersonalInformation?.PostCode,
          city: event?.PersonalInformation?.City,
          address_line_1: event?.PersonalInformation?.AddressLine1,
          address_line_2: event?.PersonalInformation?.AddressLine2,
          // tickets: event?.Tickets,
          message: event?.Message,
          ticket: myTicket
        },
        description: ""
      });

      // event Data Ticket
      event?.Tickets?.forEach((eventTicket: any) => {
        const myTicket = getTicket(eventTicket?.TicketId);
        const startTimeTicket = new Date(eventTicket?.StartDate?.seconds * 1000);
        const endTimeTicket = new Date(eventTicket?.EndDate?.seconds * 1000);
        eventDataByTicketNew.push({
          title: `${event?.PersonalInformation?.FirstName} ${event?.PersonalInformation?.LastName} ${myTicket?.Title}`,
          start: startTimeTicket,
          end: endTimeTicket,
          color: myTicket?.color,
          // random colors for each event but not event Ticket, with outline of ticket color
          editable: true,
          startEditable: true,
          durationEditable: true,
          extendedProps: {
            id: `${event?.id}`,
            isEventBooking: event?.PaymentMethod === "cash" ? false : true,
            vititors: eventTicket?.Visitors,
            ticket: myTicket
          },
          description: ""
        });
      });
    });
    // console.log({eventDataNew, eventDataByTicketNew})
    setEvents(eventDataNew);
    setEventTickets(eventDataByTicketNew);
  }, [selectedEvents, ticketsRedux]);
  function getTicket(id: string) {
    if (!ticketsRedux) return;
    var returnObj: any = {};
    ticketsRedux.forEach((ticket: any) => {
      if (ticket.id === id) {
        returnObj = ticket;
      }
    });
    return returnObj;
  }
  function eventClick(e: any) {
    setIsEditReservierungVisible(true);
    setSelectedEvent(e?.event);
    setSelectedId(e?.event?.extendedProps?.id);
    setEventIsBooking(e?.event?.extendedProps?.isEventBooking);
    // console.log(e?.event, e?.event?.title, e?.event?.start, { events })
  }

  function dateClick(e: any) {
    if (calendarEl?.current?.getApi()?.view?.type == "dayGridMonth") {
      calendarEl?.current?.getApi().changeView("dayGridWeek", e?.date);
    } else if (calendarEl?.current?.getApi()?.view?.type == "dayGridWeek") {
      calendarEl?.current?.getApi().changeView("timeGridDay", e?.date);
    } else if (calendarEl?.current?.getApi()?.view?.type == "timeGridDay") {
      calendarEl?.current?.getApi().changeView("listWeek", e?.date);
    }
  }
  function findArrayIndex(arr: any[], obj: any) {
    let returnIndex = -1;
    arr.forEach((ob, i) => {
      if (returnIndex > -1) return;
      if (ob?.extendedProps?.id == obj?.extendedProps?.id) returnIndex = i;
    });
    return returnIndex;
  }

  // open event changed modal
  function toggleEventChanged() {
    // why did the event change?
    // did customer call you?
    setIsWhyDidEventChangeVisible(!isWhyDidEventChangeVisible);
    setWhyDidReservierungChangeKey(whyDidReservierungChangeKey + 1);
    setEventChangeKey(eventChangeKey + 1);
  }
  function eventContent(innerProps: any) {
    // console.log({ innerProps }, innerProps.event.extendedProps.people, innerProps?.view)
    if (innerProps?.view?.type === "timeGridDay") return <div style={{
      backgroundColor: innerProps?.backgroundColor,
      borderRadius: "0.5rem",
      padding: "0.1rem",
      color: innerProps?.textColor,
      maxWidth: "100%",
      whiteSpace: "pre-wrap"
    }}>
					<p style={{
        margin: 0,
        padding: 0
      }}>
						{innerProps.timeText}
					</p>
					<p style={{
        margin: 0,
        padding: 0
      }}>
						{innerProps.event.title},&nbsp;
						{innerProps.event.extendedProps.vititors}
					</p>
				</div>;else if (innerProps?.view?.type === "listWeek") {
      return <div style={{
        backgroundColor: "white",
        borderRadius: "0.5rem",
        padding: "0.1rem",
        color: innerProps?.textColor,
        maxWidth: "100%",
        whiteSpace: "pre-wrap"
      }}>
					<p style={{
          margin: 0,
          padding: 0
        }}>
						{innerProps.timeText}
					</p>
					<p style={{
          margin: 0,
          padding: 0
        }}>
						{innerProps.event.title},&nbsp;
						{innerProps.event.extendedProps.vititors}
					</p>
				</div>;
    }
  }
  function closeEditReservierungen() {
    setIsEditReservierungVisible(false);
  }
  function saveWhyDidEventChangeFunction(data: any) {
    // console.log({ data })
    setIsWhyDidEventChangeVisible(false);
  }
  function closeAreYouSureDialog() {
    setAreYouSureIsVisible(false);
  }
  function openAreYouSureDialog() {
    setAreYouSureIsVisible(true);
  }
  function deleteFunction() {
    if (!selectedEvent?.extendedProps) {
      toast.error("There was an error!");
      return;
    }
    if (selectedEvent?.extendedProps?.isEventBooking) {
      deleteBooking(selectedEvent?.extendedProps?.id).then(res => {
        toast.success("Deleted Booking");
        closeAreYouSureDialog();
        closeEditReservierungen();
      }).catch(err => {
        console.log({
          err
        });
        toast.error("Error while deleting Booking!");
      });
    } else {
      deleteReservation(selectedEvent?.extendedProps?.id).then(res => {
        toast.success("Deleted Reservation");
        closeAreYouSureDialog();
        closeEditReservierungen();
      }).catch(err => {
        console.log({
          err
        });
        toast.error("Error while deleting Reservation!");
      });
    }
  }
  async function deleteBooking(id: string) {
    const myDoc = doc(db, `/Bookings/${id}/`);
    return await deleteDoc(myDoc);
  }
  async function deleteReservation(id: string) {
    const myDoc = doc(db, `/Reservations/${id}/`);
    return await deleteDoc(myDoc);
  }

  // ganz unten sollte eine Legende der Tickets sein
  return <div>
			{/* <AddReservierungModal
            key={`AddRes ${addResKey}`}
            visible={isAddReservierungVisible}
            closeFunction={closeAddReservierungen} 
        /> */}
			<AreYouSureComponent isOpen={isAreYouSureVisible} title={"Are you sure you want to delete this Event?"} noFunction={closeAreYouSureDialog} yesFunction={deleteFunction} />
			<EditEventModal key={`EditRes ${selectedId}`} visible={isEditReservierungVisible} eventId={selectedId} closeFunction={closeEditReservierungen} isEventBooking={isEventBooking} deleteFunction={openAreYouSureDialog} />
			<WhyDidReservierungChangeModal key={`WhyChange ${whyDidReservierungChangeKey}`} visible={isWhyDidEventChangeVisible} saveFunction={saveWhyDidEventChangeFunction} eventName={selectedEvent?.title || "Event"} />
			<div style={{
      maxWidth: "500px",
      margin: "auto"
    }}>
				<AccordionCustomComponent title={"Filter"}>
					<div style={{
          padding: "0 0.5rem",
          textAlign: "start"
        }} id="Events Calendar Filter">
						{/* <Button onClick={addReservierung} variant="contained">Add Reservierung</Button> */}
						<Stack direction="row" spacing={1} alignItems="center">
							<Typography>Show full Event</Typography>
							<Switch defaultChecked checked={isShowEachTicket} onChange={() => {
              setIsShowEachTicket(!isShowEachTicket);
            }} />
							<Typography>Show each Ticket</Typography>
						</Stack>
						<Typography onClick={() => {
            setShowReservationsIsActive(!isShowReservationsActive);
          }}>
							<Checkbox checked={isShowReservationsActive} /> Show
							Reservations
						</Typography>
						<Typography onClick={() => {
            setShowBookingsIsActive(!isShowBookingsActive);
          }}>
							<Checkbox checked={isShowBookingsActive} /> Show
							Bookings
						</Typography>
					</div>
				</AccordionCustomComponent>
				<ExportEventsOnDateButton events={events} />
			</div>
			<Paper style={{
      padding: "0.5rem",
      margin: "auto",
      maxWidth: "1200px"
    }}>
				<FullCalendar locale={deLocale} locales={[deLocale]} key={eventChangeKey} ref={calendarEl} firstDay={1} nowIndicator now={new Date()} dayMaxEvents={6} headerToolbar={{
        start: "dayGridMonth,dayGridWeek,timeGridDay,listWeek",
        center: "title",
        end: "today prev,next"
      }} events={calendarVisibleTickets} plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listWeekPlugin]} initialView="dayGridWeek" eventClick={eventClick} eventDragStop={toggleEventChanged} dateClick={dateClick} eventContent={eventContent} height={"80vh"} />
			</Paper>
		</div>;
}