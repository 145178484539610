import { block as _block$ } from "million/react";
import { useEffect, useState } from "react";
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { Button } from "@mui/material";
import { storage } from "../../../../../utilities/Firebase/firebase-redux";
import { toast } from "react-toastify";
const M$UploadButton = function UploadButton({
  label = "Upload",
  location,
  setLink
}: {
  label: string;
  location: string;
  setLink: any;
}) {
  const [file, setFile] = useState<FileList>();
  function uploadFiles(dataToUpload: FileList) {
    const urls: string[] = [];
    for (let i = 0; i < dataToUpload.length; i++) {
      const myRef = ref(storage, `${location}/${dataToUpload?.item(i)?.name}`);
      uploadBytes(myRef, dataToUpload[i]).then(e => {
        toast.success("File(s) Uploaded successfully");
        getDownloadURL(ref(storage, e.metadata.fullPath)).then(url => {
          urls.push(url);
          setLink(urls);
        });
      }).catch(error => {
        toast.error("Couldn't Upload File(s)");
        console.log({
          error
        });
      });
    }
    setLink(urls);
  }
  useEffect(() => {
    if (!file) return;
    uploadFiles(file);
  }, [file]);
  return <div style={{
    padding: "1em"
  }}>
			<label htmlFor={"inputButton"} style={{
      color: "#979797",
      fontSize: "14px",
      margin: "0"
    }}>
				<input multiple onChange={(e: any) => {
        setFile(e.target.files);
      }} type="file" style={{
        display: "none"
      }} id={"inputButton"} accept={`image/*`} />
				<Button component="span" fullWidth variant="contained">
					{label}
				</Button>
			</label>
		</div>;
};
const UploadButton = M$UploadButton;
export { UploadButton };