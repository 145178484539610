import { Button, ListItemIcon, ListItemText, Paper, Typography, MenuList, Menu, MenuItem, IconButton, Box, TextField } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import HttpTwoToneIcon from '@mui/icons-material/HttpTwoTone';
import { useState } from "react";
import { EmailTemplateSelect } from "components/common/ui/Selects/EmailTemplate-Select";
import { useTranslation } from "react-i18next";
import BaseDialogComponent from "components/common/layouts/Dialog";
export default function WorkflowDialog({
  open,
  closeFunction,
  deleteFunction,
  updateFunction
}: {
  open: boolean;
  closeFunction: any;
  deleteFunction: any;
  updateFunction: any;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [workflows, setWorkflows] = useState<any[]>([]);
  const isMenuVisible = Boolean(anchorEl);
  const {
    t
  } = useTranslation("common");
  function closeAnchor() {
    setAnchorEl(null);
  }
  function addNewWorkflow(workflow: string) {
    console.log({
      workflows
    });
    const workflowsNew: any[] = structuredClone(workflows);
    switch (workflow) {
      case "email":
        {
          workflowsNew.push(<EmailWorkflow setAnchor={(event: any) => {
            setAnchorEl(event?.currentTarget);
          }} key={`Email Workflow ${workflows?.length}`} />);
          break;
        }
      case "webhook":
        {
          workflowsNew.push(<WebhookWorkflow setAnchor={(event: any) => {
            setAnchorEl(event?.currentTarget);
          }} key={`Webhook Workflow ${workflows?.length}`} />);
          break;
        }
    }
    setWorkflows(workflowsNew);
    closeAnchor();
  }
  return <BaseDialogComponent isOpen={open} title={"Workflow Dialog"} closeFunction={closeFunction} deletFunction={deleteFunction} updateFunction={updateFunction}>
        <Box sx={{
      textAlign: 'center',
      backgroundColor: "gray"
    }}>
            <Paper style={{
        padding: '0.5rem'
      }}>
                <Typography typography={"h2"}>
                    Mögliche Eingaben:
                </Typography>
                <Typography typography={"span"} align="left">
                    {"{{FirstName}}"}
                </Typography>
                <Typography typography={"span"} align="left">
                    {"{{LastName}}"}
                </Typography>
                <Typography typography={"span"} align="left">
                    {"{{...}}"}
                </Typography>
            </Paper>

            <Paper style={{
        padding: '0.5rem',
        margin: "0.25rem 0"
      }}>
                <TextField fullWidth label="Title" />
            </Paper>

            <Box>
                <IconButton style={{
          backgroundColor: "white"
        }} onClick={(event: any) => {
          setAnchorEl(event?.currentTarget);
        }}>
                    <AddBoxIcon />
                </IconButton>

                {workflows}
            </Box>

            <Menu onClose={closeAnchor} open={isMenuVisible} anchorEl={anchorEl}>
                <MenuList>
                    <MenuItem onClick={() => {
            addNewWorkflow("email");
          }}>
                        <ListItemIcon>
                            <EmailTwoToneIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Send Email</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => {
            addNewWorkflow("webhook");
          }}>
                        <ListItemIcon>
                            <HttpTwoToneIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Send Webhook</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    </BaseDialogComponent>;
}
function Spacer() {
  return <div style={{
    width: '5px',
    height: '10px',
    backgroundColor: 'white',
    margin: 'auto'
  }}></div>;
}
function EmailWorkflow({
  setAnchor
}: {
  setAnchor: any;
}) {
  return <>
        <Spacer />

        <Paper style={{
      padding: '0.5rem'
    }}>
            <Typography>Email</Typography>
            <EmailTemplateSelect setTemplate={() => {}} />
        </Paper>

        <Spacer />

        <IconButton style={{
      backgroundColor: "white"
    }} onClick={setAnchor}>
            <AddBoxIcon />
        </IconButton>
    </>;
}
function WebhookWorkflow({
  setAnchor
}: {
  setAnchor: any;
}) {
  return <>
        <Paper style={{
      padding: '0.5rem'
    }}>
            <Typography>Webhook</Typography>
            <TextField label="Link" placeholder="https://example.com/webhook" fullWidth />
        </Paper>

        <Spacer />

        <IconButton style={{
      backgroundColor: "white"
    }} onClick={setAnchor}>
            <AddBoxIcon />
        </IconButton>
    </>;
}