import { Grid } from "@mui/material";
import { AreWeOpenAccordion } from "components/base/Settings/iFrames/are-we-open";
import { BusinessCalendar } from "components/base/Settings/iFrames/business-calendar";
import { ParkEventProcess } from "components/base/Settings/iFrames/park-event-process";
import { ParkPage } from "components/base/Settings/iFrames/park-page";
import { PrivatePageLayout } from "components/common/layouts/Page/private";
import { useTranslation } from "react-i18next";
export function IframePage() {
  const {
    t
  } = useTranslation("common");
  return <PrivatePageLayout link={"https://images.pexels.com/photos/7116676/pexels-photo-7116676.jpeg?auto=compress&cs=tinysrgb"} activeLink={"Settings"} activeSubLink={"iFrames"} title={t("title.iFrames")} //iFrames
  >
			<Grid container style={{
      maxWidth: "500px",
      margin: "auto"
    }} spacing={0}>
				<Grid item xs={12}>
					<AreWeOpenAccordion />
				</Grid>
				<Grid item xs={12}>
					<BusinessCalendar />
				</Grid>
				<Grid item xs={12}>
					<ParkPage />
				</Grid>
				<Grid item xs={12}>
					<ParkEventProcess />
				</Grid>
			</Grid>
		</PrivatePageLayout>;
}