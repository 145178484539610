import { Button, Grid, MenuItem } from "@mui/material";
import AreYouSureComponent from "components/base/Dialogs/Warnings/are-you-sure";
import { useState } from "react";
import { GroupingTemplate } from "../../../../common/layouts/Settings/Grouping-Template/grouping-template";
import { useSelector } from "react-redux";
import { SettingsListItem } from "components/common/layouts/Settings/Settings-List-Item";
import EditTicketPriceGroupDialog from "./ticket-price-grouping-dialog";
export function TicketPriceGrouping() {
  const activeSeasonTicketGroupingsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTicketsGroups);
  const [groupingSelectedId, setGroupingSelectedId] = useState("");
  const [isGroupingsOpen, setIsGroupingsOpen] = useState(false);
  const [isAreYouSureVisible, setAreYouSureIsVisible] = useState(false);
  function closeAreYouSureFunction() {
    setAreYouSureIsVisible(false);
  }
  function openAreYouSureFunction() {
    setAreYouSureIsVisible(true);
  }
  function deleteTicketPriceGroupingFunction() {
    if (!groupingSelectedId) return;
    // deleteGrouping(groupingSelectedId);
    closeAreYouSureFunction();
    closeEditTicketPriceGroupingFunction();
  }
  function openEditTicketPriceGroupingFunction(id: string) {
    setIsGroupingsOpen(true);
    setGroupingSelectedId(id);
  }
  function closeEditTicketPriceGroupingFunction() {
    setIsGroupingsOpen(false);
    setGroupingSelectedId("");
  }
  function addNewTicketPriceGrouping() {
    // const myCol = collection(db, `/Parks/${uid}/Groupings/`)
    // addDoc(myCol, {Title: "", CreatedOn: Date.now()})
    //     .then((res)=>{
    //         const groupingId = res.id;
    //         openEditGroupingFunction(groupingId)
    //         toast.success("Added a new Grouping!")
    //     })
    //     .catch((err)=>{
    //         toast.error("Failed adding a new Grouping")
    //     })
  }
  return <Grid container spacing={1} style={{
    textAlign: "center",
    marginTop: "1rem"
  }}>
			<AreYouSureComponent isOpen={isAreYouSureVisible} title={`Do you really want to delete this Ticket Price Grouping?`} noFunction={closeAreYouSureFunction} yesFunction={deleteTicketPriceGroupingFunction} />

			<EditTicketPriceGroupDialog visible={isGroupingsOpen} closeFunction={closeEditTicketPriceGroupingFunction} id={groupingSelectedId} deleteTicketFunction={openAreYouSureFunction} />

			<GroupingTemplate isAddButtonVisible title={"Ticket Price Grouping"} addNewFunction={addNewTicketPriceGrouping}>
				{activeSeasonTicketGroupingsRedux?.map((ticket: any) => {
        return <SettingsListItem key={`Grid ${ticket.id}`} title={ticket?.Title || ""}>
							<MenuItem onClick={() => {
            openEditTicketPriceGroupingFunction(ticket?.id);
          }}>
								Edit
							</MenuItem>
							<MenuItem onClick={openAreYouSureFunction}>
								Delete
							</MenuItem>
						</SettingsListItem>;
      })}
			</GroupingTemplate>
		</Grid>;
}