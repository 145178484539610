import { Badge, Grid, Typography } from "@mui/material";
import { BusinessHourSelect } from "./business-hour-select";
import type { PickersDayProps } from "@mui/x-date-pickers";
import { DateCalendar, DayCalendarSkeleton, PickersDay } from "@mui/x-date-pickers";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LoadingSpinnerModal } from "components/common/layouts/Loading-Spinner/loading-spinner-modal";
import { addBusinessHourFirebase, deleteBusinessHourFirebase, updateBusinessHourFirebase } from "./lib/firebase-helper";
import { getLocaleDateString } from "./lib/date-helper";
import type { TicketGroupFirebaseObject, TimeTextFirebaseObject } from "./lib/business-hour-helper";
import { findIndexOfExistingDateTimeInArray, getBusinessHour, getTicketGroups, getTimeTexts } from "./lib/business-hour-helper";
import { useTranslation } from "react-i18next";
export function BusinessHourCalendar({
  startDate,
  endDate,
  currentSeason
}: {
  startDate: Dayjs;
  endDate: Dayjs;
  currentSeason: number;
}) {
  const activeSeasonBusinessHoursRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonBusinessHours);
  const activeSeasonTimingGroupsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTimingGroups);
  const activeSeasonTicketGroupingsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTicketsGroups);
  const activeSeasonPricesRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonPrices);
  const activeSeasonTicketsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTickets);
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const [calendarSelectedBusinessHourId, setCalendarSelectedBusinessHourId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const {
    t
  } = useTranslation("common");
  function onCalendarDateClick(dayjsDate: Dayjs | null) {
    if (!dayjsDate) return;
    setCurrentDate(dayjsDate);
    if (calendarSelectedBusinessHourId === "" || calendarSelectedBusinessHourId === "none") return;
    setIsLoading(true);
    const startOfDayDateTime: number = dayjsDate.set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate().getTime();
    const endOfDayDateTime: number = dayjsDate.set("hours", 23).set("minutes", 59).set("seconds", 59).set("milliseconds", 999).toDate().getTime();
    const existingObject: any = findIndexOfExistingDateTimeInArray(getLocaleDateString(startOfDayDateTime), activeSeasonBusinessHoursRedux);
    if (calendarSelectedBusinessHourId === "delete") {
      if (!existingObject?.id) {
        setIsLoading(false);
        return;
      }
      deleteBusinessHourFirebase(uid, existingObject?.id).then(res => {
        setIsLoading(false);
        toast.success("Deleted Business Hour sucessfully");
      }).catch(err => {
        setIsLoading(false);
        console.error({
          err
        });
        toast.error("Error while deleting Business Hours");
      });
      return;
    }
    const selectedBusinessHour: any = getBusinessHour(calendarSelectedBusinessHourId, activeSeasonTimingGroupsRedux);
    const ticketGroups: TicketGroupFirebaseObject[] = getTicketGroups(selectedBusinessHour, startOfDayDateTime, activeSeasonTicketGroupingsRedux, activeSeasonTicketsRedux, activeSeasonPricesRedux); // Ticket Grouping bspw. "Schulklasse"

    const newTimeTextArray: TimeTextFirebaseObject[] = getTimeTexts(selectedBusinessHour, startOfDayDateTime); // Text that is being shown in iFrame between curtain dates (defaults to "closed")

    // final BusinessHour Object to upload in Firebase
    const businessHourData = {
      // calendar date time
      Start: {
        DateTime: startOfDayDateTime,
        Timezone: "Europe/Berlin"
      },
      End: {
        DateTime: endOfDayDateTime,
        Timezone: "Europe/Berlin"
      },
      Title: selectedBusinessHour?.Title || "",
      Description: selectedBusinessHour?.Description || "",
      Color: selectedBusinessHour?.Color || "",
      BusinessHourId: selectedBusinessHour?.id || "",
      TicketGroups: ticketGroups,
      TimeTexts: newTimeTextArray,
      Season: `Season ${currentSeason}`,
      CreatedOn: Date.now()
    };
    if (!existingObject?.id) {
      addBusinessHourFirebase(businessHourData, uid, getLocaleDateString(startOfDayDateTime)).then(res => {
        setIsLoading(false);
        toast.success(t('success.businessHours.added'));
      }).catch(err => {
        setIsLoading(false);
        console.error({
          err
        });
        toast.error(t('error.businessHours.added'));
      });
    } else {
      updateBusinessHourFirebase(businessHourData, uid, existingObject?.id).then(res => {
        setIsLoading(false);
        toast.success(t('success.businessHours.updated'));
      }).catch(err => {
        setIsLoading(false);
        console.error({
          err
        });
        toast.error(t('error.businessHours.updated'));
      });
    }
  }
  return <Grid container>
			<LoadingSpinnerModal loading={isLoading} />
			<Grid item xs={12}
    // sm={3}
    >
				<Typography typography={"h6"}>{t('title.ticketTimeGroups')}</Typography>
				<BusinessHourSelect setBusinessHour={setCalendarSelectedBusinessHourId} />
			</Grid>
			<Grid item xs={12}
    // sm={9}
    >
				<DateCalendar key={`Business Hours ${activeSeasonBusinessHoursRedux?.length}`} minDate={startDate} maxDate={endDate} value={currentDate} showDaysOutsideCurrentMonth onChange={onCalendarDateClick} renderLoading={() => <DayCalendarSkeleton />} slots={{
        day: ServerDay
      }} slotProps={{
        day: ({
          highlighted: activeSeasonBusinessHoursRedux
        } as any)
      }} />
			</Grid>
		</Grid>;
}
interface PickersDayPropsExtended extends PickersDayProps<Dayjs> {
  highlighted?: any[];
}
function ServerDay(props: PickersDayPropsExtended) {
  const {
    highlighted = [],
    day,
    outsideCurrentMonth,
    today,
    ...other
  } = props;
  const date = day.toDate();
  const dateTime = date.getTime();
  let color = "";
  highlighted.forEach(businessHour => {
    const businessHourDateTime = businessHour?.Start?.DateTime;
    if (businessHourDateTime !== dateTime) return;
    color = businessHour?.Color;
  });
  return <Badge key={props.day.toString()} overlap="circular">
			<PickersDay style={{
      backgroundColor: color
    }} outsideCurrentMonth={outsideCurrentMonth} day={day} {...other} />
		</Badge>;
}