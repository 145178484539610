import { Grid } from "@mui/material";
import EmailTemplateSettings from "components/base/Settings/Email-Templates/email-templates";
import { PrivatePageLayout } from "components/common/layouts/Page/private";
import { useTranslation } from "react-i18next";
export function EmailSettingsPage() {
  const {
    t
  } = useTranslation("common");
  return <PrivatePageLayout link={"https://images.pexels.com/photos/7116676/pexels-photo-7116676.jpeg?auto=compress&cs=tinysrgb"} activeLink={"Settings"} activeSubLink={"Email Templates"} title={t("title.emailTemplates")}>
			<Grid container style={{
      maxWidth: "500px",
      margin: "auto"
    }} spacing={0}>
				<Grid item xs={12}>
					<EmailTemplateSettings />
				</Grid>
			</Grid>
		</PrivatePageLayout>;
}