import { Grid } from "@mui/material";
import TimingGroup from "components/base/Hours-And-Tickets/Times/timeGroup/timeGroups";
import { PrivatePageLayout } from "components/common/layouts/Page/private";
import { useTranslation } from "react-i18next";
export function TimingsPage() {
  const {
    t
  } = useTranslation("common");
  return <PrivatePageLayout link={"https://images.pexels.com/photos/1834407/pexels-photo-1834407.jpeg?auto=compress&cs=tinysrgb"} activeLink={"tickets,prices,times"} activeSubLink={"times"} title={t("title.timings")}>
			<Grid container style={{
      maxWidth: "1000px",
      margin: "auto"
    }} spacing={0}>
				<Grid item xs={12}>
					<TimingGroup />
				</Grid>
			</Grid>
		</PrivatePageLayout>;
}