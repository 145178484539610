import { Button, TextField, Grid, Typography, TextareaAutosize, Tabs, Tab, Box } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Formik, Field } from "formik";
import { useEffect, useState } from "react";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import SwipeableViews from "react-swipeable-views";
import { db } from "utilities/Firebase/firebase-redux";
import SelectNewTicket from "../old/select-new-ticket";
import { EnterTimeManuallyDialog } from "../old/enter-time-manually";
import BaseDialogComponent from "components/common/layouts/Dialog";
export default function EditEventModal({
  visible,
  eventId,
  closeFunction,
  isEventBooking,
  deleteFunction
}: {
  visible: boolean;
  eventId: string;
  closeFunction: any;
  isEventBooking: boolean;
  deleteFunction: any;
}) {
  const bookingsRedux = useSelector((state: any) => state.firestore.ordered.bookings);
  const reservationsRedux = useSelector((state: any) => state.firestore.ordered.reservations);
  const ticketsRedux = useSelector((state: any) => state.firestore.ordered.tickets);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState<boolean>(false);
  const [isManualDateVisible, setIsManualDateVisible] = useState<boolean>(false);
  const [isNewTicketVisible, setIsNewTicketVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [event, setEvent] = useState<any>({});
  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");
  const [message, setMessage] = useState("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [tel, setTel] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [postCode, setPostCode] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [date, setDate] = useState<Dayjs>(dayjs(0));
  const [visitors, setVisitors] = useState<number[]>([]);
  const [ticketTimes, setTicketTimes] = useState<any[]>([{}]);
  const [tickets, setTickets] = useState<any[]>([]);
  const [ticketsHTML, setTicketsHTML] = useState<any[]>([<></>]);
  const [currentSelectedTicket, setCurrentSelectedTicket] = useState<number>(0);
  const [tabValue, setTabValue] = useState(0);
  const [customerEmailStatus, setCustomerEmailStatus] = useState("");
  const [customerEmailSentDate, setCustomerEmailSentDate] = useState(new Date(0));
  const [customerEmailId, setCustomerEmailId] = useState("");
  // const [customerEmailTemplateId, setCustomerEmailTemplateId] = useState("")
  const [parkEmailStatus, setParkEmailStatus] = useState("");
  const [parkEmailSentDate, setParkEmailSentDate] = useState(new Date(0));
  const [parkEmailId, setParkEmailId] = useState("");
  const [eventCreationDate, setEventCreationDate] = useState(new Date(0));
  function getDurationInSeconds(duration: string): number {
    const timeSplit = duration.split(":");
    const minutesInSeconds = Number(timeSplit[1]) * 60;
    const hoursInSeconds = Number(timeSplit[0]) * 60 * 60;
    return minutesInSeconds + hoursInSeconds;
  }
  async function updateBooking(data: any) {
    const myDoc = doc(db, `/Bookings/${eventId}/`);
    return await setDoc(myDoc, data, {
      merge: true
    });
  }
  async function updateReservation(data: any) {
    const myDoc = doc(db, `/Reservations/${eventId}/`);
    return await setDoc(myDoc, data, {
      merge: true
    });
  }
  async function getEmailData(id: string) {
    const myDoc = doc(db, `/Emails/${id}`);
    return await getDoc(myDoc);
  }
  useEffect(() => {
    if (!customerEmailId || customerEmailId === "") return;
    getEmailData(customerEmailId).then(res => {
      const data = res.data();
      setCustomerEmailStatus(data?.delivery?.state);
      const sentDate = new Date(data?.delivery?.endTime?.seconds ? data?.delivery?.endTime?.seconds * 1000 : data?.delivery?.endTime);
      setCustomerEmailSentDate(sentDate);
    });
  }, [customerEmailId]);
  useEffect(() => {
    if (!parkEmailId || parkEmailId === "") return;
    getEmailData(parkEmailId).then(res => {
      const data = res.data();
      setParkEmailStatus(data?.delivery?.state);
      const sentDate = new Date(data?.delivery?.endTime?.seconds ? data?.delivery?.endTime?.seconds * 1000 : data?.delivery?.endTime);
      setParkEmailSentDate(sentDate);
    });
  }, [parkEmailId]);
  useEffect(() => {
    if (!ticketsRedux || !event) return;
    const newTickets: any[] = [];
    const newTicketTimes: any[] = [];
    const newVisitorAmount: number[] = [];
    event?.Tickets?.forEach((eventTicket: any, i: number) => {
      const isCustomTicket = eventTicket?.TicketId === "custom" ? true : false;
      const myTicket = getTicket(eventTicket?.TicketId);
      const startDate: Date = new Date(eventTicket?.StartDate?.seconds * 1000);
      const start: Dayjs = isCustomTicket ? dayjs(eventTicket?.StartDate?.seconds * 1000) : dayjs(startDate).set("year", date.year()).set("month", date.month()).set("date", date.date());
      const isBusinessHoursEndOfDayUsed = myTicket?.isBusinessHoursEndOfDayUsed;
      const isSpecificTimeUsed = myTicket?.isSpecificTimeUsed;
      const durationInSeconds = getDurationInSeconds(myTicket?.duration || "00:00");
      const endDate: Date = new Date(eventTicket?.EndDate?.seconds * 1000) ? new Date(eventTicket?.EndDate?.seconds * 1000) : isSpecificTimeUsed ? new Date(myTicket?.endTimeOfTicket.seconds * 1000) : isBusinessHoursEndOfDayUsed ? new Date() : new Date(startDate.getTime() + durationInSeconds * 1000);
      const end: Dayjs = isCustomTicket ? dayjs(eventTicket?.EndDate?.seconds * 1000) : dayjs(endDate).set("year", date.year()).set("month", date.month()).set("date", date.date());
      const visitors = eventTicket?.Visitors;
      newTicketTimes.push({
        start,
        end,
        visitors
      });
      newVisitorAmount.push(visitors);
      newTickets.push({
        start: start,
        end: end,
        visitorAmount: visitors,
        ticketTitle: isCustomTicket ? eventTicket?.Title : myTicket?.name,
        ticketId: isCustomTicket ? eventTicket?.TicketId : myTicket?.id,
        docId: eventTicket?.id || "",
        groupingId: eventTicket?.GroupingId,
        createdOn: eventTicket?.CreatedOn
      });
    });
    setVisitors(newVisitorAmount);
    setTicketTimes(newTicketTimes);
    setTickets(newTickets);
  }, [event, ticketsRedux, date]);
  function handleVisitorChange(visitorAmount: number, index: number) {
    const newVisitors = JSON.parse(JSON.stringify(visitors));
    newVisitors[index] = visitorAmount;
    setVisitors(newVisitors);
    const newTickets = JSON.parse(JSON.stringify(tickets));
    newTickets[currentSelectedTicket] = {
      ...newTickets[currentSelectedTicket],
      visitorAmount: visitorAmount
    };
    setTickets(newTickets);
  }
  function getTicketFromId(ticketsRedux: any[], ticketId: string) {
    let returnData = {};
    ticketsRedux?.forEach(ticket => {
      if (ticket?.id === ticketId) returnData = ticket;
    });
    return returnData;
  }
  useEffect(() => {
    if (!tickets) return;
    const newTickets: any[] = [];
    tickets?.forEach((ticket, i) => {
      const myTicket: any = getTicketFromId(ticketsRedux, ticket?.ticketId);
      newTickets.push(<div key={`Ticket ${myTicket?.id} ${i}`} style={{
        border: "1px solid black",
        margin: "1rem 0",
        borderRadius: "1rem",
        padding: "0.5rem"
      }}>
					<Typography style={{
          textAlign: "center"
        }}>
						Ticket: {myTicket?.Title}
					</Typography>
					<TextField
        // style={{width: '100%', margin: "0.5rem 10%"}}
        fullWidth label={"Visitor Amount"} type={"number"} size="small" required value={visitors[i]} onChange={(e: any) => {
          handleVisitorChange(Number(e.target.value), i);
        }} />
					<div style={{
          display: "flex",
          justifyContent: "space-around"
        }}>
						<Typography>
							Beginn:{" "}
							{new Date(ticketTimes[i].start.toDate()).toLocaleTimeString("de", {
              hour: "2-digit",
              minute: "2-digit"
            })}
						</Typography>
						<Typography>
							Ende:{" "}
							{new Date(ticketTimes[i].end.toDate()).toLocaleTimeString("de", {
              hour: "2-digit",
              minute: "2-digit"
            })}
						</Typography>
					</div>
					<Grid container spacing={0}>
						<Grid item xs={12} style={{
            padding: "0.5rem"
          }}>
							<Button fullWidth variant="contained" onClick={() => {
              toggleNewTicketSelect(i);
            }}>
								Anderes Ticket waehlen
							</Button>
						</Grid>
						<Grid xs={12} style={{
            padding: "0.5rem"
          }}>
							<Button fullWidth variant="contained" onClick={() => {
              toggleManualDate(i);
            }}>
								Uhrzeit manuell eingeben
							</Button>
						</Grid>
					</Grid>
				</div>);
    });
    setTicketsHTML(newTickets);
  }, [tickets, visitors, ticketTimes, ticketsRedux]);
  useEffect(() => {
    if (!bookingsRedux || !reservationsRedux) return;
    if (isEventBooking) {
      bookingsRedux?.forEach((booking: any) => {
        console.log({
          booking
        });
        if (booking?.id !== eventId) return;
        setEvent(booking);
        setFirstName(booking?.PersonalInformation?.FirstName || "");
        setLastName(booking?.PersonalInformation?.LastName || "");
        setEmail(booking?.PersonalInformation?.Email || "");
        setTel(booking?.PersonalInformation?.PhoneNumber || "");
        setCity(booking?.PersonalInformation?.City || "");
        setPostCode(booking?.PersonalInformation?.PostCode || "");
        setAddressLine1(booking?.PersonalInformation?.AddressLine1 || "");
        setAddressLine2(booking?.PersonalInformation?.AddressLine2 || "");
        setMessage(booking?.Message || "");
        setNote(booking?.Note || "");
        setTitle(`${booking?.PersonalInformation?.FirstName} ${booking?.PersonalInformation?.LastName}`);
        setDate(dayjs(new Date(booking?.Date?.seconds * 1000)));
        setCustomerEmailId(booking?.Email || "");
        setParkEmailId(booking?.ParkEmail || "");
        setEventCreationDate(new Date(booking?.CreatedOn || 0));
      });
    } else {
      reservationsRedux?.forEach((reservation: any) => {
        if (reservation?.id !== eventId) return;
        setEvent(reservation);
        setFirstName(reservation?.PersonalInformation?.FirstName || "");
        setLastName(reservation?.PersonalInformation?.LastName || "");
        setEmail(reservation?.PersonalInformation?.Email || "");
        setTel(reservation?.PersonalInformation?.PhoneNumber || "");
        setCity(reservation?.PersonalInformation?.City || "");
        setPostCode(reservation?.PersonalInformation?.PostCode || "");
        setAddressLine1(reservation?.PersonalInformation?.AddressLine1 || "");
        setAddressLine2(reservation?.PersonalInformation?.AddressLine2 || "");
        setMessage(reservation?.Message || "");
        setNote(reservation?.Note || "");
        setTitle(`${reservation?.PersonalInformation?.FirstName} ${reservation?.PersonalInformation?.LastName}`);
        setDate(dayjs(new Date(reservation?.Date?.seconds * 1000)));
        setCustomerEmailId(reservation?.Email || "");
        setParkEmailId(reservation?.ParkEmail || "");
        setEventCreationDate(new Date(reservation?.CreatedOn || 0));
      });
    }
  }, [bookingsRedux, reservationsRedux, ticketsRedux, isEventBooking]);
  function getTicket(id: string) {
    if (!ticketsRedux) return;
    var returnObj: any = {};
    ticketsRedux.forEach((ticket: any) => {
      if (ticket.id === id) {
        returnObj = ticket;
      }
    });
    return returnObj;
  }
  function toggleNewTicketSelect(index: number) {
    setCurrentSelectedTicket(index);
    setIsNewTicketVisible(true);
  }
  function toggleManualDate(index: number) {
    setCurrentSelectedTicket(index);
    setIsManualDateVisible(true);
  }
  function saveUpdateTicket(data: any) {
    const newTicketTimes = JSON.parse(JSON.stringify(ticketTimes));
    const start = dayjs(new Date(data.start));
    start.set("year", date.year()).set("month", date.month()).set("date", date.date());
    const end = dayjs(new Date(data.end));
    end.set("year", date.year()).set("month", date.month()).set("date", date.date());
    newTicketTimes[currentSelectedTicket] = {
      start: start,
      end: end
    };
    setTicketTimes(newTicketTimes);
    const newTickets = JSON.parse(JSON.stringify(tickets));
    newTickets[currentSelectedTicket] = {
      ...newTickets[currentSelectedTicket],
      end: end,
      start: start,
      ticketId: data.id,
      ticketTitle: data.title
    };
    setTickets(newTickets);
    abortUpdateTicket();
  }
  function abortUpdateTicket() {
    setIsNewTicketVisible(false);
  }
  function getVisitorAmount(visitorsArray: number[]) {
    var returnNumber: number = 0;
    visitorsArray.forEach(visitor => {
      returnNumber += visitor;
    });
    return returnNumber;
  }
  function updateFunction() {
    const visitorAmount: number = getVisitorAmount(visitors);
    const ticketArray = tickets?.map((ticket, i) => {
      const newData = {
        TicketId: ticket?.ticketId,
        Visitors: ticket?.visitorAmount,
        StartDate: new Date(ticket?.start) || ticket?.start?.toDate(),
        EndDate: new Date(ticket?.end) || ticket?.end?.toDate(),
        UpdatedOn: Date.now(),
        CreatedOn: ticket.createdOn,
        GroupingId: ticket.groupingId
      };
      return newData;
    });
    const personalInformation = {
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      City: city,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: tel,
      PostCode: postCode
    };
    const updateEventData = {
      Tickets: ticketArray,
      Date: date.toDate(),
      PersonalInformation: personalInformation,
      VisitorAmount: visitorAmount,
      Note: note,
      UpdatedOn: Date.now()
    };
    if (isEventBooking) {
      updateBooking(updateEventData).then(res => {
        toast.success("Updated Booking");
        closeFunction();
      }).catch(err => {
        console.log({
          err
        });
        toast.error("Error while updating Booking");
      });
    } else {
      updateReservation(updateEventData).then(res => {
        toast.success("Updated Reservation");
        closeFunction();
      }).catch(err => {
        console.log({
          err
        });
        toast.error("Error while updating Reservation");
      });
    }
  }
  function closeNewDateAndTimesDialog() {
    setIsManualDateVisible(false);
  }
  function saveNewDateAndTimes(timeData: any) {
    const newData: any[] = JSON.parse(JSON.stringify(ticketTimes));
    const start = dayjs(timeData?.start);
    start.set("year", date.year()).set("month", date.month()).set("date", date.date());
    const end = dayjs(timeData?.end);
    end.set("year", date.year()).set("month", date.month()).set("date", date.date());
    newData[currentSelectedTicket] = {
      start,
      end
    };
    setTicketTimes(newData);
    const newTickets = JSON.parse(JSON.stringify(tickets));
    newTickets[currentSelectedTicket] = {
      ...newTickets[currentSelectedTicket],
      end: end,
      start: start
    };
    setTickets(newTickets);
  }
  function handleTabValueChange(index: number) {
    setTabValue(index);
  }
  function handleSwipeValueChange(event: React.SyntheticEvent, index: number) {
    setTabValue(index);
  }
  return <BaseDialogComponent isOpen={visible} closeFunction={closeFunction} title={`Edit ${isEventBooking ? "Booking:" : "Reservation:"} ${title}`} deletFunction={deleteFunction} updateFunction={updateFunction}>
			<SelectNewTicket visible={isNewTicketVisible} saveFunction={saveUpdateTicket} abortFunction={abortUpdateTicket} date={date.toDate()} />
			<EnterTimeManuallyDialog visible={isManualDateVisible} closeFunction={closeNewDateAndTimesDialog} saveFunction={saveNewDateAndTimes} defaultValues={{
      start: ticketTimes[currentSelectedTicket]?.start,
      end: ticketTimes[currentSelectedTicket]?.end
    }} />
			<Tabs value={tabValue} variant="scrollable" onChange={handleSwipeValueChange} scrollButtons="auto">
				<Tab label="Personal Information" value={0} />
				<Tab label="Selected Tickets" value={1} />
				<Tab label="Arrivals" value={2} />
				<Tab label="Payment Information" value={3} />
				<Tab label="Email" value={4} />
				<Tab label="Meta Data" value={5} />
			</Tabs>
			<SwipeableViews index={tabValue} onChangeIndex={handleTabValueChange}>
				<TabPanel index={0} value={tabValue}>
					<Formik initialValues={{}} onSubmit={() => {}}>
						<>
							{/* <h2 style={{ padding: 0, margin: 0, fontWeight: '400', fontSize: '1.25rem' }}>Personal Information</h2>
                            <hr style={{ margin: '0 0 0.5rem 0' }} /> */}
							<Grid container spacing={1}>
								<Grid item xs={12} sm={6}>
									<Field component={TextField} label={"First Name"} id="first_name" type={"text"} size="small" fullWidth value={firstName} onChange={(e: any) => {
                  setFirstName(e.target.value);
                }} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field component={TextField} label={"Last Name"} id="last_name" type={"text"} size="small" fullWidth value={lastName} onChange={(e: any) => {
                  setLastName(e.target.value);
                }} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field component={TextField} label={"Email"} id="email" type={"email"} size="small" fullWidth value={email} onChange={(e: any) => {
                  setEmail(e.target.value);
                }} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field component={TextField} label={"Phone Number"} id="phone_number" size="small" fullWidth value={tel} onChange={(e: any) => {
                  setTel(e.target.value);
                }} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field component={TextField} label={"Address Line 1"} id="address_line_1" type={"text"} size="small" fullWidth value={addressLine1} onChange={(e: any) => {
                  setAddressLine1(e.target.value);
                }} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field component={TextField} label={"Address Line 2"} id="address_line_2" type={"text"} size="small" fullWidth value={addressLine2} onChange={(e: any) => {
                  setAddressLine2(e.target.value);
                }} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field component={TextField} label={"Postal Code"} id="postal_code" type={"number"} size="small" fullWidth value={postCode} onChange={(e: any) => {
                  setPostCode(e.target.value);
                }} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field component={TextField} label={"City"} id="city" type={"text"} size="small" fullWidth value={city} onChange={(e: any) => {
                  setCity(e.target.value);
                }} />
								</Grid>
							</Grid>
							<div style={{
              marginTop: "2rem"
            }}>
								<Typography variant="body1" style={{
                border: "1px solid black",
                borderRadius: "0.5rem",
                padding: "0.5rem"
              }}>
									<Typography variant="body2" style={{
                  margin: 0,
                  padding: 0,
                  backgroundColor: "white",
                  position: "relative",
                  top: -20,
                  left: 15,
                  width: "fit-content",
                  fontWeight: "400",
                  fontSize: "1.25rem"
                }}>
										Message
									</Typography>
									{message}
								</Typography>
							</div>
							<div style={{
              marginTop: "2rem"
            }}>
								<Typography variant="body1" style={{
                border: "1px solid black",
                borderRadius: "0.5rem",
                padding: "0.5rem"
              }}>
									<Typography variant="body2" style={{
                  margin: 0,
                  padding: 0,
                  backgroundColor: "white",
                  position: "relative",
                  top: -20,
                  left: 15,
                  width: "fit-content",
                  fontWeight: "400",
                  fontSize: "1.25rem"
                }}>
										Note
									</Typography>
									<TextareaAutosize style={{
                  width: "100%"
                }} value={note} onChange={e => {
                  setNote(e?.target?.value);
                }} minRows={5} placeholder="Eine Notiz, die nur ich sehen kann..." />
								</Typography>
							</div>
						</>
					</Formik>
				</TabPanel>
				<TabPanel index={1} value={tabValue}>
					<MobileDatePicker label="Date"
        // inputFormat="DD.MM.YYYY"
        value={date} onChange={(e: any) => {
          setDate(e);
        }}
        // renderInput={(params) => <TextField id="date" size="small" fullWidth {...params} />}
        />
					<div>{ticketsHTML}</div>
				</TabPanel>
				<TabPanel index={2} value={tabValue}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={6}>
							<Button fullWidth variant="contained">
								Full Visitor Arrival
							</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button fullWidth variant="contained">
								Partial Visitor Arrival
							</Button>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel index={3} value={tabValue}>
					Payment Method, Payment Amount, Payment Data
				</TabPanel>
				<TabPanel index={4} value={tabValue}>
					<Typography typography={"h3"}>
						Email To Customer:
					</Typography>
					<Typography>Email Status: {customerEmailStatus}</Typography>
					<Typography>
						Email Sent On:{" "}
						{customerEmailSentDate.toLocaleDateString("de")}{" "}
						{customerEmailSentDate.toLocaleTimeString("de")}
					</Typography>
					{/* <Typography>Email Template: {emailTemplate}</Typography> */}
					<hr />
					<Typography typography={"h3"}>Email To Park:</Typography>
					<Typography>Email Status: {parkEmailStatus}</Typography>
					<Typography>
						Email Sent On:{" "}
						{parkEmailSentDate.toLocaleDateString("de")}{" "}
						{parkEmailSentDate.toLocaleTimeString("de")}
					</Typography>
					{/* <Typography>Email Template: {emailTemplate}</Typography> */}
				</TabPanel>
				<TabPanel index={5} value={tabValue}>
					<Typography>
						Finished Event Process On:{" "}
						{eventCreationDate.toLocaleDateString("de")}{" "}
						{eventCreationDate.toLocaleTimeString("de")}
					</Typography>
					{/* <Typography>Browser: {Browser}</Typography> */}
					{/* <Typography>IP: {IP}</Typography> */}
					{/* <Typography>Device: {Device}</Typography> */}
					{/* <Typography>Last Updated on: {emailStatus}</Typography> */}
					{/* <Typography>Last Updated by: {emailStatus}</Typography> */}
					{/* <Typography>Last Update reason: {emailStatus}</Typography> */}
				</TabPanel>
			</SwipeableViews>
		</BaseDialogComponent>;
}
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
			{value === index && <Box sx={{
      p: 3
    }}>
					<Typography>{children}</Typography>
				</Box>}
		</div>;
}