import { useState } from "react";
import { Grid, Paper } from "@mui/material";
import { MediaList } from "components/common/components/Media-List";
import { PrivatePageLayout } from "components/common/layouts/Page/private";
import EditMediaModal from "components/base/Dialogs/Edit-Dialogs/edit-media";
import AreYouSureComponent from "components/base/Dialogs/Warnings/are-you-sure";
import { deleteObject, ref } from "firebase/storage";
import { storage } from "utilities/Firebase/firebase-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
export function MediaLibraryPage() {
  const [isEditMediaDialogVisible, setEditMediaDialogIsVisible] = useState(false);
  const [isAreYouSureVisible, setAreYouSureIsVisible] = useState(false);
  const [mediaSrc, setMediaSrc] = useState("");
  const {
    t
  } = useTranslation("common");
  function closeEditMediaDialog() {
    setMediaSrc("");
    setEditMediaDialogIsVisible(false);
  }
  function openEditMediaDialog(src: string) {
    setMediaSrc(src);
    setEditMediaDialogIsVisible(true);
  }
  function closeAreYouSureDialog() {
    setAreYouSureIsVisible(false);
  }
  function deleteFunction() {
    const mediaRef = ref(storage, mediaSrc);
    deleteObject(mediaRef).then(res => {
      closeAreYouSureDialog();
      closeEditMediaDialog();
      toast.success("Deleted Media");
    }).catch(err => {
      toast.error("Deleted Error");
    });
  }
  return <PrivatePageLayout link={"https://images.pexels.com/photos/16626497/pexels-photo-16626497.jpeg?auto=compress&cs=tinysrgb"} activeLink={"Settings"} activeSubLink="media-library" title={t("title.mediaLibrary")}>
			<EditMediaModal deleteFunction={() => {
      setAreYouSureIsVisible(true);
    }} key={`Edit Media Dialog ${mediaSrc}`} mediaSrc={mediaSrc} isOpen={isEditMediaDialogVisible} closeFunction={closeEditMediaDialog} />
			<AreYouSureComponent isOpen={isAreYouSureVisible} title={"Are you sure you want to delete this image?"} yesFunction={deleteFunction} noFunction={closeAreYouSureDialog} />
			<Grid container style={{
      maxWidth: "500px",
      margin: "auto"
    }} spacing={0}>
				<Grid item xs={12}>
					<Paper elevation={3} style={{
          borderRadius: "1rem",
          padding: "0.5rem"
        }}>
						<MediaList key={`Media List ${isEditMediaDialogVisible}`} imageOnClickFunction={openEditMediaDialog} />
					</Paper>
				</Grid>
			</Grid>
		</PrivatePageLayout>;
}