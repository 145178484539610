import { Grid, IconButton, Paper, Typography } from "@mui/material";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
export function GroupingTemplate({
  title,
  addNewFunction,
  children,
  isAddButtonVisible = false
}: {
  title: string;
  addNewFunction?: any;
  children?: any;
  isAddButtonVisible?: boolean;
}) {
  return (
    // <Grid
    // 	item
    // 	xs={12}
    // >
    <Paper elevation={9} style={{
      padding: '0.5rem',
      margin: 'auto'
    }}>
			<Typography style={{
        textAlign: "center"
      }} typography={"h2"}>
				{title}
			</Typography>
			<div
      // style={{
      // 	whiteSpace: "nowrap",
      // 	display: "flex",
      // 	overflowY: "hidden",
      // 	overflowX: "auto",
      // 	justifyContent: "start",
      // 	alignContent: "center",
      // 	alignItems: "center",
      // 	padding: "0.5rem 0",
      // }}
      style={{
        display: 'block',
        justifyContent: "start",
        alignContent: "center",
        alignItems: "center",
        padding: "0.5rem 0"
      }}>
				{isAddButtonVisible && <IconButton onClick={addNewFunction} style={{
          margin: 'auto'
        }}>
						<AddCircleTwoToneIcon />
					</IconButton>}
				{children}
			</div>
		</Paper>
    // </Grid>
  );
}