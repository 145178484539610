import { Button, Grid, Paper, Typography } from "@mui/material";
import RegistrationFormEmail from "./registration-form-email";
import { useNavigate } from "react-router";
export default function Registration() {
  const navigate = useNavigate();
  return <Paper elevation={3} style={{
    maxWidth: "500px",
    textAlign: "center",
    margin: "3rem auto auto auto",
    padding: "1rem",
    borderRadius: "1rem",
    opacity: "1",
    backgroundColor: "white",
    backdropFilter: "blur(150px)",
    WebkitBackdropFilter: "blur(15p0x)"
  }}>
			<Typography typography={"h1"}>Registration</Typography>
			<RegistrationFormEmail />
			<Grid container spacing={2} style={{
      marginTop: "1rem"
    }}>
				<Grid item xs={12}>
					<Button fullWidth variant="text" onClick={() => {
          navigate("/login");
        }}>
						Login
					</Button>
				</Grid>
			</Grid>
		</Paper>;
}