import { Divider, Grid } from "@mui/material";
import { FormDialogLayout } from "components/common/layouts/Dialog/form-dialog-layout";
import { useEffect, useState } from "react";
import { MuiColorInput } from "mui-color-input";
import { TimeTicketGroupingItem } from "components/base/Hours-And-Tickets/Times/timeGroup/time-ticket-grouping-item";
import { BusinessHoursTimeText } from "components/base/Hours-And-Tickets/Times/timeGroup/business-hours-time-text";
import { useFormik } from "formik";
export function ShowBusinessDayDialog({
  visible,
  id,
  setOldData,
  closeFunction,
  businessHoursArray
}: {
  visible: boolean;
  id: string;
  setOldData: any;
  closeFunction: any;
  businessHoursArray: any[];
}) {
  const [businessHoursTextsKey, setBusinessHoursTextsKey] = useState(0);
  function getTicketTimeIndex(ticketEvent: any, ticketTimes: any[]) {
    var returnVal = -1;
    ticketTimes?.forEach((ticketTime, index) => {
      if (ticketTime?.startTime === ticketEvent?.start?.dateTime && ticketTime?.endTime === ticketEvent?.end?.dateTime) returnVal = index;
    });
    return returnVal;
  }
  function findOldBusinessHourById(oldId: string) {
    var returnVal;
    businessHoursArray?.forEach((businessDay: any) => {
      if (businessDay?.id !== oldId) return;
      returnVal = businessDay;
    });
    return returnVal;
  }
  useEffect(() => {
    if (!businessHoursArray || !id) return;
    const myDoc: any = findOldBusinessHourById(id);
    if (!myDoc) return;
    const ticketTimes: any[] = [];
    myDoc?.TicketGroups?.forEach((ticketGroup: any) => {
      ticketGroup?.TicketEventGroups?.forEach((ticketEventGroup: any) => {
        ticketEventGroup?.TicketEvents?.forEach((ticketEvent: any) => {
          if (getTicketTimeIndex(ticketEvent, ticketTimes) >= 0) return;
          ticketTimes.push({
            startTime: ticketEvent?.start?.dateTime,
            endTime: ticketEvent?.end?.dateTime,
            ticketGroupIds: []
          });
        });
      });
    });
    myDoc?.TicketGroups?.forEach((ticketGroup: any) => {
      ticketGroup?.TicketEventGroups?.forEach((ticketEventGroup: any) => {
        ticketEventGroup?.TicketEvents?.forEach((ticketEvent: any) => {
          const index = getTicketTimeIndex(ticketEvent, ticketTimes);
          const newTicketGroupId = ticketTimes[index]?.ticketGroupIds;
          if (newTicketGroupId?.includes(ticketGroup?.TicketGroupId)) return;
          newTicketGroupId.push(ticketGroup?.TicketGroupId);
          ticketTimes[index].ticketGroupIds = newTicketGroupId;
        });
      });
    });
    const timeTexts: any[] = myDoc?.TimeTexts?.map((timeText: any) => {
      return {
        startTime: timeText?.Start?.DateTime,
        endTime: timeText?.End?.DateTime,
        text: timeText?.Text
      };
    });
    formik.setFieldValue("timings", structuredClone(ticketTimes) || []);
    formik.setFieldValue("businessHoursTimeTexts", structuredClone(timeTexts) || []);
    formik.setFieldValue("color", myDoc?.Color || "");
    setBusinessHoursTextsKey(businessHoursTextsKey + 1);
  }, [businessHoursArray, id]);
  const formik = useFormik<any>({
    initialValues: {
      timings: [],
      color: "",
      businessHoursTimeTexts: []
    },
    // validationSchema: PersonalInformationSchema,
    onSubmit: loadOldData
  });
  function loadOldData(values: any) {
    if (!id) return;
    setOldData(values);
  }
  return <FormDialogLayout isOpen={visible} title={`Load Old Business Hour Data`} closeFunction={closeFunction} submitFunction={formik.handleSubmit} submitButtonText="Load">
			<Grid container spacing={1}>
				<Grid item xs={12} style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center"
      }}>
					<MuiColorInput required fullWidth error={formik.touched.color && Boolean(formik.errors.color)} size="small" label="Farbe" name="color" value={formik.values.color} onChange={(color: string) => {
          formik.setFieldValue("color", color);
        }} />
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						{formik.values.timings?.map((ticketTimeGroup: any, index: number) => {
            return <TimeTicketGroupingItem key={`Time Ticket ${index} ${ticketTimeGroup?.startTime} ${ticketTimeGroup?.endTime}`} startTime={ticketTimeGroup?.startTime} endTime={ticketTimeGroup?.endTime} tickets={ticketTimeGroup?.ticketGroupIds || []} setTime={() => {}} setTickets={() => {}} deleteTimeGroup={() => {}} />;
          })}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						{formik.values.businessHoursTimeTexts?.map((businessHourTimeText: any, index: number) => {
            return <BusinessHoursTimeText key={`Time Text ${index} ${businessHoursTextsKey}`} startTime={businessHourTimeText?.startTime} endTime={businessHourTimeText?.endTime} text={businessHourTimeText?.text} setBusinessHourTimeText={() => {}} deleteBusinessHourTimeText={() => {}} />;
          })}
					</Grid>
				</Grid>
			</Grid>
		</FormDialogLayout>;
}